import React, { useState, useEffect, useContext } from "react";
import Select from "react-select";
import DataContext from "../../../utils/Context/DataContext";
import { AppConfig } from "../../../config";

import { Dialog, makeStyles } from "@material-ui/core";
import { Modal } from "../../../assets/libraries/bootstrap/js/bootstrap";
import CouponModal from "./CouponModal";
import { BorderTopRounded } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  root: {
    [theme.breakpoints.down("xs")]: {
      alignItems: "flex-end", // push the dialog to bottom
    },
  },
  paper: {
    // make the content full width
    [theme.breakpoints.down("xs")]: {
      margin: 0,
      maxWidth: "100%",
      width: "100%",
      overflow: "scroll",
      paddingBottom: "45px",
      maxHeight: "100% !important",
    },
    [theme.breakpoints.up("md")]: {
      overflow: "scroll",
      maxWidth: "50%",
      width: "100%",
      height: "",
    },
  },
}));

export default function CartNew(props: any) {
  const context: any = useContext(DataContext);
  const [width, SetWidth] = React.useState(innerWidth);

  const classes = useStyles();
  const [add_delivery_open, SetAddDeliveryOpen] = useState(false);
  const [add_vendor_open, SetAddvendorOpen] = useState(false);
  const [total_open, SetTotalOpen] = useState(false);
  const [vendorinstra, SetVendorInstr] = useState(false);
  const [coupon_modal, SetCouponModal] = useState({
    is_open: false,
  });
  const [add_contact_person, SetAddContactPerson] = useState({
    is_open: false,
  });
  const [section_type, SetSectionType] = useState([
    { label: "All", value: "all" },
    { label: "Food", value: "food" },
    { label: "Grocery", value: "grocery" },
    { label: "Pickup Drop", value: "pickup_drop" },
    { label: "Near By Store", value: "near_by_store" },
  ]);

  const [delivery_type, SetDeliveryType] = useState([
    { label: "Hyper Local", value: "hyperlocal" },
    { label: "Courier", value: "courier" },
    { label: "Schedule", value: "schedule" },
  ]);

  const [delivery_point, SetDeliveryPoint] = useState([
    { label: "Single", value: "single" },
    { label: "Multiple", value: "multiple" },
  ]);

  useEffect(() => {});

  return (
    <div className="mb-3 custom-container bg-body-color">
      <div className="row bg-fff pt-3 pb-4 sticky-top shadow-bottom border-bottom-radius">
        <div className="col-4 px-2">
          <div className="card radius-10 shadow p-2">
            <div className="cart-filter-head">Section Type</div>
            <Select
              className="cart_select"
              // styles={{
              //   menuPortal: (base) => ({ ...base, zIndex: 9999 }),
              // }}
              options={section_type}
              defaultValue={section_type[0]}
            />
          </div>
        </div>
        <div className="col-4 px-0">
          <div className="card radius-10 shadow p-2">
            <div className="cart-filter-head">Delivery Type</div>
            <Select
              className="cart_select"
              // styles={{
              //   menuPortal: (base) => ({ ...base, zIndex: 9999 }),
              // }}
              options={delivery_type}
              defaultValue={delivery_type[0]}
            />
          </div>
        </div>
        <div className="col-4 px-2">
          <div className="card radius-10 shadow p-2">
            <div className="cart-filter-head">Delivery Point</div>
            <Select
              className="cart_select"
              // styles={{
              //   menuPortal: (base) => ({ ...base, zIndex: 9999 }),
              // }}
              options={delivery_point}
              defaultValue={delivery_point[0]}
            />
          </div>
        </div>
      </div>
      <div className="px-2 pt-3 pb-5 mb-5">
        <div className="card p-2 shadow radius-10 mb-3">
          <div className="row pb-2">
            <div className="col-2 p-0 pe-2">
              <img
                src={
                  AppConfig.CDN_Image_url +
                  "62eeaf50fa0a9a609efe846632b0fc47.jpg"
                }
                className="cart-res-img"
              />
            </div>
            <div className="col-9 p-0">
              <div className="cart-head">New Gowri Sankar Hotel</div>
              <div className="cart-sub">Erode Brough Road</div>
            </div>
            <div className="col-1 p-0 text-right cart-check pt-1">
              <input
                className="form-check-input   m-0" // { c_ele.checked ?
                //     : "form-check-input m-0"
                // }
                type="checkbox"
              />
            </div>
          </div>
          <div className="row pb-1 align-items-center">
            <div className="col-6 ps-0">
              <div className="d-flex">
                <img
                  src={
                    AppConfig.CDN_Image_url +
                    "e301ce3af353aab421a2f76c898a439d.png"
                  }
                  className="w-12px mt-1"
                />
                <div className="">
                  <div className="ps-2 cart-prod-name">Mexican Strips</div>
                  <div className="d-flex align-items-center ps-2">
                    <div className="customize-accrodion">Customize</div>
                    <img
                      src={
                        AppConfig.CDN_Media_url +
                        "b1b871802f6048caa74336bf068322cb.svg"
                      }
                      className="mt-1 gray-accordion-icon"
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="col-3 p-0">
              <div className="border p-2 radius-10 d-flex align-items-center w-100">
                <div className="center w-20">
                  <img
                    src={
                      AppConfig.CDN_Media_url +
                      "1b669eb5a49a8f4778ec18b862af8324.svg"
                    }
                    className="type-img"
                  />
                </div>
                <div className="fs-12px text-dark w-80 center">1</div>
                <div className="center w-20 ">
                  <img
                    src={
                      AppConfig.CDN_Media_url +
                      "ed4c4c5f46f208f8238c965eaaa7c21f.svg"
                    }
                    className="type-img"
                  />
                </div>
              </div>
            </div>

            <div className="col-3 pe-0 ps-1 end">
              <div className="fs-12 black-text pop-font">₹ 150.60</div>
            </div>
          </div>

          <div className="row pb-1 align-items-center">
            <div className="col-6 ps-0">
              <div className="d-flex">
                <img
                  src={
                    AppConfig.CDN_Image_url +
                    "e301ce3af353aab421a2f76c898a439d.png"
                  }
                  className="w-12px mt-1"
                />
                <div className="">
                  <div className="ps-2 cart-prod-name">Mexican Strips</div>
                  <div className="d-flex align-items-center ps-2">
                    <div className="customize-accrodion">Customize</div>
                    <img
                      src={
                        AppConfig.CDN_Media_url +
                        "b1b871802f6048caa74336bf068322cb.svg"
                      }
                      className="mt-1 gray-accordion-icon"
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="col-3 p-0">
              <div className="border p-2 radius-10 d-flex align-items-center w-100">
                <div className="center w-20">
                  <img
                    src={
                      AppConfig.CDN_Media_url +
                      "1b669eb5a49a8f4778ec18b862af8324.svg"
                    }
                    className="type-img"
                  />
                </div>
                <div className="fs-12px text-dark w-80 center">1</div>
                <div className="center w-20 ">
                  <img
                    src={
                      AppConfig.CDN_Media_url +
                      "ed4c4c5f46f208f8238c965eaaa7c21f.svg"
                    }
                    className="type-img"
                  />
                </div>
              </div>
            </div>

            <div className="col-3 pe-0 ps-1 end">
              <div className="fs-12 black-text pop-font">₹ 150.60</div>
            </div>
          </div>

          <div className="border p-2 radius-10 bg-fff my-2">
            <div className="d-flex align-items-center">
              <img
                src={
                  AppConfig.CDN_Media_url +
                  "09346edef1ebfd53661ba4e78141c1d4.svg"
                }
                className="w-16px me-1"
              />
              <div className="loc-add fw-bold fs-10px">
                Save ₹100 and get extra cashback with GETFLAT100
              </div>
              <div className="ms-auto d-flex">
                <img
                  src={
                    AppConfig.CDN_Media_url +
                    "e98699888811e6a8f0554f6bebc69add.svg"
                  }
                  className="w-11px"
                />
              </div>
            </div>
          </div>

          {total_open ? (
            <div className="mt-2 border-bottom px-1">
              <div className="d-flex align-items-center pb-1">
                <div className="v-small black-text pop-regular">Sub Total</div>
                <div className="ms-auto d-flex align-items-center">
                  <div className="strike pop-regular text-gray fs-9px pe-2">
                    ₹ 390.00
                  </div>
                  <div className="v-small black-text pop-regular">₹ 370.60</div>
                </div>
              </div>
              <div className="d-flex align-items-center pb-1">
                <div className="v-small black-text pop-regular">GST Amount</div>
                <div className="ms-auto d-flex align-items-center">
                  <div className="v-small black-text pop-regular">₹ 10.00</div>
                </div>
              </div>
              <div className="d-flex align-items-center pb-1">
                <div className="v-small black-text pop-regular">
                  Delivery Fee (5.2 km)
                </div>
                <div className="ms-auto d-flex align-items-center">
                  <div className="strike pop-regular text-gray fs-9px pe-2">
                    ₹ 14.00
                  </div>
                  <div className="v-small black-text pop-regular">₹ 10.00</div>
                </div>
              </div>
              <div className="d-flex align-items-center pb-1">
                <div className="v-small black-text pop-regular">
                  Convince Fee
                </div>
                <div className="ms-auto d-flex align-items-center">
                  <div className="strike pop-regular text-gray fs-9px pe-2">
                    ₹ 14.00
                  </div>
                  <div className="v-small black-text pop-regular">₹ 10.00</div>
                </div>
              </div>
            </div>
          ) : null}

          <div className="d-flex align-items-center py-2 px-1">
            <div
              className="d-flex align-items-center"
              onClick={() => {
                SetTotalOpen(!total_open);
              }}
            >
              <div className="fs-12 text-dark pop-font">Total</div>
              <img
                src={
                  AppConfig.CDN_Media_url +
                  "b1b871802f6048caa74336bf068322cb.svg"
                }
                className={
                  total_open ? "rotate-180 ms-2 w-10px" : "ms-2 w-10px"
                }
              />
            </div>
            <div className="ms-auto">
              <div className="fs-12 text-dark pop-font">₹ 400.60</div>
            </div>
          </div>

          {/* Single delivery point start */}
          {/* <div className="py-2 d-flex align-items-center w-100">
            <div className="w-50  d-flex align-items-center">
              <img
                src={
                  AppConfig.CDN_Media_url +
                  "08d72b9e3d07a0472c3c7a56c4dba9d6.svg"
                }
                className="w-13"
              />
              <div className="fs-12 ps-2 text-black pop-regular">
                Hyper Local
              </div>
              <img
                src={
                  AppConfig.CDN_Media_url +
                  "b1b871802f6048caa74336bf068322cb.svg"
                }
                className="mt-1 gray-accordion-icon"
              />
            </div>
            <div className="w-50 end">
              <div className="fs-12 pe-2 text-black pop-regular">
                Delivery in 40mins
              </div>
              <img
                src={
                  AppConfig.CDN_Media_url +
                  "bdd81626c3045fc4d115c50283af01ce.svg"
                }
                className="w-15"
              />
            </div>
          </div> */}
          {/* Single delivery point end */}

          {/* multiple delivery point */}
          <div className="py-2 px-1 px-0 d-flex align-items-center w-100">
            <div className="d-flex align-items-center  ps-0">
              <div className="fs-12 text-gray pop-font-light pe-1 let_space_03">
                Delivering to
              </div>
              <div className="fs-12 pe-1 pop-font text-dark">HOME</div>
              <img
                src={
                  AppConfig.CDN_Media_url +
                  "b1b871802f6048caa74336bf068322cb.svg"
                }
                className=" ms-1 w-11px"
              />
            </div>
            <div className="ms-auto">
              <img
                src={
                  AppConfig.CDN_Media_url +
                  "21d3a9e5cedf31f69753210bf5015802.svg"
                }
                className="w-14px"
              />
            </div>
          </div>

          {/* accordion open */}
          <div className="fs-11px text-gray pop-regular let_space_03 px-1 pb-2">
            Erode Junction, Chennimalai Road, Railway Colony, Erode, Erode,
            Tamil Nadu, India, 638002
          </div>

          <div className="py-2 px-1 d-flex align-items-center w-100 border-top">
            <div className="fs-12 text-gray pop-font-light pe-1 let_space_03">
              Contact
            </div>
            {add_contact_person ? (
              <div className="fs-12 ps-1 pe-2 pop-font text-dark">
                Anu | +919874563210
              </div>
            ) : (
              <div className="fs-12 ps-1 pe-2 pop-font text-dark">Myself</div>
            )}

            <div
              className="ms-auto"
              onClick={() => {
                SetAddContactPerson({
                  is_open: true,
                });
              }}
            >
              <img
                src={
                  AppConfig.CDN_Media_url +
                  "21d3a9e5cedf31f69753210bf5015802.svg"
                }
                className="w-14px"
              />
            </div>
          </div>
          {/* accordion end */}

          <div className="py-2 px-1 d-flex align-items-center w-100 border-top">
            <div className="w-50  d-flex align-items-center">
              <img
                src={
                  AppConfig.CDN_Media_url +
                  "08d72b9e3d07a0472c3c7a56c4dba9d6.svg"
                }
                className="w-14px"
              />
              <div className="fs-12 ps-2 text-dark  pop-regular">
                Hyper Local
              </div>
              <img
                src={
                  AppConfig.CDN_Media_url +
                  "b1b871802f6048caa74336bf068322cb.svg"
                }
                className="ms-1 w-11px"
              />
            </div>
            <div className="w-50 end">
              <div className="fs-12 pe-2 text-dark pop-regular">
                Delivery in 40mins
              </div>
              <img
                src={
                  AppConfig.CDN_Media_url +
                  "bdd81626c3045fc4d115c50283af01ce.svg"
                }
                className="w-16px"
              />
            </div>
          </div>
          {/* multiple delivery point end */}

          {/* common instruction start */}
          <div className="border-top py-2 px-1">
            {vendorinstra ? (
              <div>
                <div className="os-instra fw-bold let_space_03">
                  Vendor Instructions
                </div>
                <div className="fs-11px text-gray pop-regular let_space_03">
                  Make a dish extra spicy and extra sauce
                </div>
              </div>
            ) : (
              <div
                className="row align-items-center"
                onClick={() => {
                  SetAddvendorOpen(true);
                }}
              >
                <div className="col-11 ps-0 os-instra">
                  Add vendor instructions
                </div>
                <div className="col-1 p-0 end">
                  <img
                    src={
                      AppConfig.CDN_Media_url +
                      "77ff56db2e971c20be91c491e5b50729.svg"
                    }
                    className="w-14px"
                  />
                </div>
              </div>
            )}
          </div>

          <div className="border-top py-2 px-1">
            <div
              className="row align-items-center"
              onClick={() => {
                SetAddDeliveryOpen(true);
              }}
            >
              <div className="col-11 ps-0 os-instra">
                Add Delivery instructions
              </div>
              <div className="col-1 p-0 end">
                <img
                  src={
                    AppConfig.CDN_Media_url +
                    "77ff56db2e971c20be91c491e5b50729.svg"
                  }
                  className="w-14px"
                />
              </div>
            </div>
            {/* <div>
              <div className="os-instra fw-bold let_space_03">
                Delivery Instructions
              </div>
              <div className="fs-11px text-gray pop-regular let_space_03">
                Make a call before reach the delivery location
              </div>
            </div> */}
          </div>
          {/* common instruction end */}
        </div>

        {/* single delivery point */}

        {/* <div className="card p-2 shadow radius-10 mb-2">
          <div className="py-2 d-flex align-items-center">
            <div className="fs-11px text-dark pop-font pe-1 let_space_03">
              Delivering to
            </div>
            <div className="fs-12 pe-2 pop-font text-dark">HOME</div>
            <img
              src={
                AppConfig.CDN_Media_url + "21d3a9e5cedf31f69753210bf5015802.svg"
              }
              className="w-14px"
            />
          </div>
          <div className="fs-11px text-gray pop-regular let_space_03">
            Erode Junction, Chennimalai Road, Railway Colony, Erode, Erode,
            Tamil Nadu, India, 638002
          </div>
          <div className="pt-3 pb-2 d-flex align-items-center w-100">
            <div className="w-50  d-flex align-items-center">
              <img
                src={
                  AppConfig.CDN_Media_url +
                  "1acf785b7860b8ff62147bd01e501d51.svg"
                }
                className="w-13"
              />
              <div className="fs-12 ps-2 text-black pop-regular">Username</div>
            </div>
            <div className="w-50 end">
              <div className="fs-12 pe-2 text-black pop-regular">987456123</div>
              <img
                src={
                  AppConfig.CDN_Media_url +
                  "b07293a39f4df3c962204eba4d8cb1b3.svg"
                }
                className="w-13"
              />
            </div>
          </div>
        </div> */}
        {/* single delivery point */}

        <div className="card px-2 py-3 shadow radius-10 mb-2">
          <div className="d-flex align-items-cener w-100">
            <div className="cart-head w-50">Payment Type</div>
            <div className="w-50  end">
              <div className="fs-11px px-2 text-black pop-font fw-600 let_space_03">
                Online
              </div>
              <img
                src={
                  AppConfig.CDN_Media_url +
                  "b1b871802f6048caa74336bf068322cb.svg"
                }
                className="w-11px"
              />
            </div>
          </div>
        </div>

        {/* <div className="card p-2 shadow radius-10 mb-2">
          <div className="d-flex align-items-center w-100">
            <img
              src={
                AppConfig.CDN_Media_url + "b1b871802f6048caa74336bf068322cb.svg"
              }
              className=" me-2 w-10px"
            />
            <div className="">
              <div className="d-flex align-items-center">
                <div className="coupon-name pe-1">Supercoupon</div>
                <div className="text-black-color pop-font fs-13px">applied</div>
              </div>
              <div className="d-flex align-items-center">
                <div className="coupon-name pe-1 fs-12">₹50</div>
                <div className="text-gray fs-12 pop-font">Coupon savings</div>
              </div>
            </div>
            <div className="v-small black-text pop-regular ms-auto">Remove</div>
          </div>
        </div> */}

        <div className="card px-2 py-3 shadow radius-10 mb-2">
          <div className="d-flex align-items-center w-100">
            <img
              src={
                AppConfig.CDN_Media_url + "09346edef1ebfd53661ba4e78141c1d4.svg"
              }
              className=" me-2 w-20px"
            />
            <div className="">
              <div className="d-flex align-items-center">
                <div className="cart-head">Apply Coupon</div>
              </div>
            </div>
            <div
              className="ms-auto d-flex align-items-center"
              onClick={() => {
                SetCouponModal({
                  is_open: true,
                });
              }}
            >
              <div className="fs-11px px-2 text-black pop-font fw-600 let_space_03">
                Choose
              </div>
              <img
                src={
                  AppConfig.CDN_Media_url +
                  "e98699888811e6a8f0554f6bebc69add.svg"
                }
                className="w-11px"
              />
            </div>
          </div>
        </div>

        <div className="card px-2 py-3 mb-2 radius-10 shadow">
          <div className="center">
            <img
              src={
                AppConfig.CDN_Image_url + "d765198e4a07c16289ffd296f69e9ced.png"
              }
              width={18}
            />
            <div className="coupon-name px-1 fs-12 text-tranform-none">
              You save ₹39.40 in this order
            </div>
          </div>
        </div>

        <div className="card px-2 py-3 shadow radius-10 mb-5">
          <div className="cart-head w-50 pb-2">Order Summary</div>
          <div className="pb-2  ">
            <div className="d-flex align-items-center pb-1">
              <div className="v-small black-text pop-regular">Sub Total</div>
              <div className="ms-auto d-flex align-items-center">
                <div className="strike pop-regular text-gray fs-9px pe-2">
                  ₹ 390.00
                </div>
                <div className="v-small black-text pop-regular">₹ 370.60</div>
              </div>
            </div>
            <div className="d-flex align-items-center pb-1">
              <div className="v-small black-text pop-regular">GST Amount</div>
              <div className="ms-auto d-flex align-items-center">
                <div className="v-small black-text pop-regular">₹ 10.00</div>
              </div>
            </div>
            <div className="d-flex align-items-center pb-1">
              <div className="v-small black-text pop-regular">
                Delivery Fee (5.2 km)
              </div>
              <div className="ms-auto d-flex align-items-center">
                <div className="strike pop-regular text-gray fs-9px pe-2">
                  ₹ 14.00
                </div>
                <div className="v-small black-text pop-regular">₹ 10.00</div>
              </div>
            </div>
            <div className="d-flex align-items-center pb-1">
              <div className="v-small black-text pop-regular">Convince Fee</div>
              <div className="ms-auto d-flex align-items-center">
                <div className="strike pop-regular text-gray fs-9px pe-2">
                  ₹ 14.00
                </div>
                <div className="v-small black-text pop-regular">₹ 10.00</div>
              </div>
            </div>
          </div>
          <div className="d-flex align-items-center border-top py-1">
            <div className="fs-13px text-dark pop-font">Grand Total</div>
            {/* <img
                src={
                  AppConfig.CDN_Media_url +
                  "b1b871802f6048caa74336bf068322cb.svg"
                }
                className="ms-2 w-12px"
              /> */}
            <div className="ms-auto">
              <div className="fs-13px text-dark pop-font">₹ 400.60</div>
            </div>
          </div>
        </div>
      </div>

      <div className="p-2 bottom-fixed bg-none w-100">
        <button
          className="primary-button w-100"
          // onClick={() => {
          // }}
        >
          Proceed to pay
        </button>
      </div>

      {/* dialog */}

      <Dialog
        classes={{ container: classes.root, paper: classes.paper }}
        className="center"
        open={add_delivery_open}
        onClose={() => {
          SetAddDeliveryOpen(false);
        }}
      >
        <div className="row align-items-center py-3 px-2">
          <div className="col-10">
            <div className="os-dialog-head">Delivery Instructions</div>
          </div>
          <div
            className="col-2 end"
            onClick={() => {
              SetAddDeliveryOpen(false);
            }}
          >
            <img
              src={
                AppConfig.CDN_Media_url + "f0e8463e68a70f6e4a7ae696c626f8aa.svg"
              }
              className="w-25px"
            />
          </div>
        </div>
        <div className="text-center p-3">
          <textarea
            className="input-textarea"
            placeholder="eg: Just knock the door"
          ></textarea>
          <div className="mt-3 os-save-btn">Save Instruction</div>
        </div>
      </Dialog>

      <Dialog
        classes={{ container: classes.root, paper: classes.paper }}
        className="center"
        open={add_vendor_open}
        onClose={() => {
          SetAddvendorOpen(false);
          SetVendorInstr(true);
        }}
      >
        <div className="row align-items-center py-3 px-2">
          <div className="col-10">
            <div className="os-dialog-head">Vendor Instructions</div>
          </div>
          <div
            className="col-2 end"
            onClick={() => {
              SetAddvendorOpen(false);
              SetVendorInstr(true);
            }}
          >
            <img
              src={
                AppConfig.CDN_Media_url + "f0e8463e68a70f6e4a7ae696c626f8aa.svg"
              }
              className="w-25px"
            />
          </div>
        </div>
        <div className="text-center p-3">
          <textarea
            className="input-textarea"
            placeholder="eg: Make a spicy food"
          ></textarea>
          <div
            className="mt-3 os-save-btn"
            onClick={() => {
              SetAddvendorOpen(false);
              SetVendorInstr(true);
            }}
          >
            Save Instruction
          </div>
        </div>
      </Dialog>

      <Dialog
        classes={{ container: classes.root, paper: classes.paper }}
        className="center"
        open={add_contact_person.is_open}
        onClose={() => {
          SetAddContactPerson({ is_open: false });
        }}
      >
        <div className="p-3 shadow ">
          <div className="center d-md-none d-sm-block pb-1">
            <hr className="hr-drawer" />
          </div>
          <div className="text-center">
            <div className="loc-head fs-16px">
              Are you ordering for someone else?
            </div>
            <div className="od-id fw-500 text-black">
              Add contact person phone number for this task to directly coordinate with them
            </div>
            
          </div>

          {/* <div className=" d-flex align-items-center">
            <img
              src={require("../../../assets/svg/location-call.svg")}
              className="w-20px"
            />
            <div className="address-sub-text fs-10px ">
              We will directly coordinate with the contact person using this
              phone number
            </div>
          </div> */}

          <div className="text-center ">
            <div className="position-relative mt-3">
              <input type="text" className="outside" required />
              <span className="floating-label-outside">
                Add contact person name
              </span>
            </div>

            <div className="position-relative mt-3">
              <input type="number" className="outside" required />
              <span className="floating-label-outside">Add phone number</span>
            </div>

            <div
              className="mt-4 light-gray-btn"
              onClick={() => {
                SetAddvendorOpen(false);
              }}
            >
              Yes, add contact person details
            </div>
            <div
              className="mt-3 light-primary-btn"
              onClick={() => {
                SetAddvendorOpen(false);
              }}
            >
              No, it's for me
            </div>
          </div>
        </div>
      </Dialog>

      <CouponModal
        open={coupon_modal.is_open}
        close={(data: any) => {
          console.log("Coupon Modal data :", data);
          SetCouponModal({
            is_open: false,
          });
        }}
      />
    </div>
  );
}
