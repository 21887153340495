import React, { useState, useEffect, useContext } from "react";
import { Link, useParams } from "react-router-dom";
import DataContext from "../../../utils/Context/DataContext";
import { api } from "../../../utils/Api";

import Skeleton from "react-loading-skeleton";
import { AppConfig } from "../../../config";
import MapLocationPickModal from "./MapLocationPickModal";


export default function AddAddressNew(props: any) {
  const context = useContext(DataContext);
  const [map_lp_modal,SetMapLPModal] = useState({
    is_open:false,
  })
  return (
    <div className="p-3">
      <div className="address-search-bar d-flex align-items-center">
        <img
          src={AppConfig.CDN_Media_url + "4075afa7aafecff54a5220213ad396f0.svg"}
          className="w-20px"
        />
        <div className="ps-2 ">
          <input
            type="text"
            className=" custom-input-text"
            placeholder="Try VOC Park, Railway Colony"
          />
        </div>
        <div className="ms-auto cursor ps-2">
          <img
            src={
              AppConfig.CDN_Media_url + "d6da0c9c3517352622470b635f8a8d38.svg"
            }
            className="w-20px"
          />
        </div>
      </div>
      {/* <div className="mt-3 card shadow p-3 radius-10">
        <div className="d-flex align-items-center pb-3 border-bottom">
          <img
            src={
              AppConfig.CDN_Media_url + "99fd4a0eb6be0e5a4d40b13df49e7b3e.svg"
            }
            className="w-16px"
          />
          <div className="ps-3 loc-add">My current location</div>
          <div className="ms-auto pop-font fs-11px text-primary">Enable</div>
        </div>
        <div className="d-flex align-items-center pt-3">
          <img
            src={
              AppConfig.CDN_Media_url + "ed0c77a7912938b1203b742ed5f5ed2e.svg"
            }
            className="w-20px"
          />
          <div className="ps-3 loc-add">Add new address</div>
        </div>
      </div> */}

      <div className="mt-3 card shadow p-3 radius-10">
        <div className="d-flex align-items-center">
          <img
            src={
              AppConfig.CDN_Media_url + "99fd4a0eb6be0e5a4d40b13df49e7b3e.svg"
            }
            className="w-16px"
          />
          <div className="ps-3 loc-add">My current location</div>
          <div className="ms-auto pop-font fs-11px text-primary" >Enable</div>
        </div>
      </div>

      <div className="mt-3 sub-head-text">Search Result</div>

      <div className="mt-3 card shadow radius-10 px-3 py-2">
        <div className="py-2 border-bottom" onClick={()=>{
            SetMapLPModal({
              is_open : true,
            })
          }}>
          <div className="d-flex align-items-center ">
            <img
              src={
                AppConfig.CDN_Media_url + "99fd4a0eb6be0e5a4d40b13df49e7b3e.svg"
              }
              className="w-14px"
            />
            <div className="ps-2 loc-add">Erode Bus Stand</div>
          </div>
          <div className="pt-1 sub-head-text fs-12px">Erode main, Tamilnadu, India</div>
        </div>
        <div className="py-2 border-bottom">
          <div className="d-flex align-items-center ">
            <img
              src={
                AppConfig.CDN_Media_url + "99fd4a0eb6be0e5a4d40b13df49e7b3e.svg"
              }
              className="w-14px"
            />
            <div className="ps-2 loc-add">Erode Bus Stand</div>
          </div>
          <div className="pt-1 sub-head-text fs-12px">Erode main, Tamilnadu, India</div>
        </div>
        <div className="py-2">
          <div className="d-flex align-items-center ">
            <img
              src={
                AppConfig.CDN_Media_url + "99fd4a0eb6be0e5a4d40b13df49e7b3e.svg"
              }
              className="w-14px"
            />
            <div className="ps-2 loc-add">Erode Bus Stand</div>
          </div>
          <div className="pt-1 sub-head-text fs-12px">Erode main, Tamilnadu, India</div>
        </div>
      </div>


      <MapLocationPickModal
        open={map_lp_modal.is_open}
        close={(data : any) => {
          console.log("Location picker on close :", data);
          SetMapLPModal({
            is_open: false,
          });
        }}
      />
    </div>
  );
}
