import React, { useState, useEffect, useContext } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import DataContext from "../../../utils/Context/DataContext";
import { api } from "../../../utils/Api";
import SwipeableBottomSheet from "@geomatico/react-swipeable-bottom-sheet";

import Skeleton from "react-loading-skeleton";
import { AppConfig } from "../../../config";
import MapLocationPickModal from "./MapLocationPickModal";

export default function AddressNew(props: any) {
  const context = useContext(DataContext);
  const navigate = useNavigate();
  const [address_menu, SetAddressMenu] = useState(false);
  const [confirm_location, SetConfirmLocation] = useState(false);
  const [edit_address, SetEditAddress] = useState({ is_open: false, data: "" });
  const [map_lp_modal, SetMapLPModal] = useState({
    is_open: false,
  });

  return (
    <div className="p-3">
      <div className="address-search-bar d-flex align-items-center">
        <img
          src={AppConfig.CDN_Media_url + "4075afa7aafecff54a5220213ad396f0.svg"}
          className="w-20px"
        />
        <div className="ps-2 ">
          <input
            type="text"
            className=" custom-input-text"
            placeholder="Enter location manually"
          />
        </div>
        <div className="ms-auto cursor ps-2">
          <img
            src={
              AppConfig.CDN_Media_url + "d6da0c9c3517352622470b635f8a8d38.svg"
            }
            className="w-20px"
          />
        </div>
      </div>

      <div className="mt-3 card shadow p-3 radius-10">
        <div className="d-flex align-items-center pb-3 border-bottom">
          <img
            src={
              AppConfig.CDN_Media_url + "99fd4a0eb6be0e5a4d40b13df49e7b3e.svg"
            }
            className="w-16px"
          />
          <div className="ps-3 loc-add">My current location</div>
          <div
            className="ms-auto pop-font fs-11px text-primary"
            onClick={() => {}}
          >
            Enable
          </div>
        </div>
        <div
          className="d-flex align-items-center pt-3"
          onClick={() => {
            navigate("/AddAddressNew");
          }}
        >
          <img
            src={
              AppConfig.CDN_Media_url + "ed0c77a7912938b1203b742ed5f5ed2e.svg"
            }
            className="w-16px"
          />
          <div className="ps-3 loc-add">Add new address</div>
        </div>
      </div>

      <div className="my-3 sub-head-text">Recent Address</div>

      <div className="card p-3 radius-10 mb-2">
        <div className="pb-2 px-0 w-100 d-flex align-items-center ">
          <img
            src={
              AppConfig.CDN_Media_url + "37465a03db5aa15f878902d80b06a7bf.svg"
            }
            className="w-14px"
          />
          <div className="fs-12 ps-2 pop-font text-dark">HOME</div>
          <div
            className="ms-auto"
            onClick={() => {
              SetAddressMenu(true);
            }}
          >
            <img
              src={
                AppConfig.CDN_Media_url + "9edfdd6a8403e3e52f981e28c5fe5c43.svg"
              }
              className="w-16px"
            />
          </div>
        </div>

        <div
          onClick={() => {
            SetConfirmLocation(true);
          }}
        >
          <div className="fs-11px text-gray pop-regular let_space_03 border-bottom pb-2">
            12/3, Erode Junction, Chennimalai Road, Railway Colony, Erode, Tamil
            Nadu, India, 638002
          </div>
          <div className="pt-2 d-flex align-items-center w-100 ">
            <div className="w-50  d-flex align-items-center">
              <img
                src={
                  AppConfig.CDN_Media_url +
                  "1acf785b7860b8ff62147bd01e501d51.svg"
                }
                className="w-13"
              />
              <div className="fs-12 ps-2 text-black pop-regular">
                Contact Person
              </div>
            </div>
            <div className="w-50 end">
              <div className="fs-12 pe-2 text-black pop-regular">987456123</div>
              <img
                src={
                  AppConfig.CDN_Media_url +
                  "b07293a39f4df3c962204eba4d8cb1b3.svg"
                }
                className="w-13"
              />
            </div>
          </div>
        </div>
      </div>

      <SwipeableBottomSheet
        open={address_menu}
        // overlay={true}
        // overflowHeight={0}
        onChange={() => {
          SetAddressMenu(false);
        }}
      >
        <div className="px-2 py-3 bg-fff sticky-top custom-swipe-shadow">
          {/* <div className="center d-md-none d-sm-block pb-3">
            <hr className="hr-drawer" />
          </div> */}
          <div className="sub-head-text mb-2 text-center">
            <span className="text-black-color fw-bold">HOME |</span>
            <span className="text-gray fw-400 ps-1">
              12/3, Erode Junction, Chennimalai Road, Railway Colony, Erode,
              Tamil Nadu, India, 638002
            </span>
            s
          </div>
          <div className="text-center px-3 py-2 ">
            <div
              className="sub-head-text cursor text-black-color py-3 border-bottom center "
              onClick={() => {
                SetAddressMenu(false);
                SetMapLPModal({
                  is_open: true,
                });
              }}
            >
              <div className="w-20px center">
                <img
                  src={
                    AppConfig.CDN_Media_url +
                    "21d3a9e5cedf31f69753210bf5015802.svg"
                  }
                  className="me-3 w-16px"
                />
              </div>
              Edit
            </div>
            <div className="sub-head-text cursor text-black-color py-3 border-bottom center ">
              <div className="w-20px center">
                <img
                  src={
                    AppConfig.CDN_Media_url +
                    "3be1da96e52838af30747800bd3f8bc3.svg"
                  }
                  className="me-2 w-16px"
                />
              </div>
              Share
            </div>
            <div className="sub-head-text cursor text-black-color py-3 center ">
              <div className="w-20px center">
                <img
                  src={
                    AppConfig.CDN_Media_url +
                    "43f75ee021f7f25970f2d20f9d504ef0.svg"
                  }
                  className="me-2 w-16px"
                />
              </div>
              Delete
            </div>
          </div>
        </div>
      </SwipeableBottomSheet>

      {/* <MapLocationPickModal
        open={map_lp_modal.is_open}
        close={(data: any) => {
          console.log("Location picker on close :", data);
          SetMapLPModal({
            is_open: false,
          });
        }}
      /> */}

      <SwipeableBottomSheet
        open={confirm_location}
        // overlay={true}
        // overflowHeight={0}
        onChange={() => {
          SetConfirmLocation(false);
        }}
      >
        <div className="px-2 py-3 text-center bg-fff sticky-top custom-swipe-shadow">
          <div className="center p-3 ">
            <img src={AppConfig.CDN_Media_url + ""} className="w-30px" />
          </div>
          <div className="loc-head mt-2">
            Are you sure of the selected location?
          </div>
          <div className="py-2 sub-head-text">
            Your selected location seems to be a little far off from the device
            location
          </div>

          <button className="primary-button mt-3">
            No, select another location
          </button>

          <button className="primary-button bg-fff w-100 text-primary mt-2">
            Yes, continue with this location
          </button>
        </div>
      </SwipeableBottomSheet>
    </div>
  );
}
