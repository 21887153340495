import React, { useState, useEffect, useContext } from "react";
import { AppConfig } from "../../../config";

export default function OrderDetailsUi(props: any) {

  return (
    <div className="mb-5 py-2 custom-container bg-body-color">
      <div className="bg-fff mb-2">
        <div className="d-flex align-items-center  p-2">
          <div className="od-id">#181</div>
          <div className="ms-auto d-flex align-items-center">
            <div className="od-status-circle blue-bg-clr"></div>
            <div className="od-status ms-1 blue-clr">Preparing</div>
          </div>
        </div>
        <div className="o-date p-2 pt-0">17-08-24 03:27pm</div>
      </div>
      <div className="bg-fff mb-2 p-2">
        <div className="shop-div row align-items-center">
          <div className="col-2 p-0">
            <img
              src={
                AppConfig.CDN_Image_url + "aa5ea240d88e138ba9158c523842b4fd.jpg"
              }
              className="os-img"
            />
          </div>
          <div className="col-7 d-flex p-0">
            <div className="line-h-17px">
              <span className="os-name">New Gowri Sankar Hotel</span>
              <span className="os-sta">
                your order delivery to delivery partner
              </span>
            </div>
          </div>
          <div className="col-3 p-0 end">
            <div className="os-icon shadow center">
              <img
                src={
                  AppConfig.CDN_Media_url +
                  "a78b858bd49bf56c9fa495b641269677.svg"
                }
                className="w-20px"
              />
            </div>
            <div className="ms-3 os-icon shadow center">
              <img
                src={
                  AppConfig.CDN_Media_url +
                  "ce8b7d884ffdb51228e7577cc36f38c0.svg"
                }
                className="w-20px"
              />
            </div>
          </div>
        </div>
      </div>

      <div className="bg-fff p-2 mb-2">
        <div className="card p-2 mb-2 shadow radius-10">
          <div className="row p-0">
            <div className="col-1 p-0">
              <img
                src={
                  AppConfig.CDN_Image_url +
                  "69e22d8519c6739a9feceef2d109c256.png"
                }
                className="w-20px"
              />
            </div>
            <div className="col-8 p-0">
              <div className="d-flex align-items-center pb-1">
                <div className="os-dark uppercase">New Gowri Sankar Hotel</div>
              </div>
              <div className="os-pname ps-0">
                Chennai - Theni Highway, Endapuli KamatchiPuram, Theni, Tamil
                Nadu, India, 625604
              </div>
            </div>
            <div className="col-3 p-0 end">
              <div className="os-icon shadow center">
                <img
                  src={
                    AppConfig.CDN_Media_url +
                    "a78b858bd49bf56c9fa495b641269677.svg"
                  }
                  className="w-20px"
                />
              </div>
              <div className="ms-3 os-icon shadow center">
                <img
                  src={
                    AppConfig.CDN_Media_url +
                    "ce8b7d884ffdb51228e7577cc36f38c0.svg"
                  }
                  className="w-20px"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="card p-2 shadow radius-10 mb-2">
          <div className="row p-0">
            <div className="col-1 p-0">
              <img
                src={
                  AppConfig.CDN_Image_url +
                  "2f0620ddc47a95162db3b88a35358317.png"
                }
                className="w-20px"
              />
            </div>
            <div className="col-8 p-0">
              <div className="d-flex align-items-center pb-1">
                <div className="os-dark pe-1">User Name -</div>
                <div className="os-dark uppercase">Office</div>
              </div>
              <div className="os-rate">Username</div>
              <div className="os-pname ps-0">
                Mary Matha College Road, Theni, Tamil Nadu, India, 625604
              </div>
            </div>
            <div className="col-3 p-0 end">
              <div className="os-icon shadow center">
                <img
                  src={
                    AppConfig.CDN_Media_url +
                    "a78b858bd49bf56c9fa495b641269677.svg"
                  }
                  className="w-20px"
                />
              </div>
              <div className="ms-3 os-icon shadow center">
                <img
                  src={
                    AppConfig.CDN_Media_url +
                    "ce8b7d884ffdb51228e7577cc36f38c0.svg"
                  }
                  className="w-20px"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="bg-fff mb-2 p-3">
        <h5 className="mb-3 fw-bold">Product Details</h5>
        <div className="row">
          <div className="col-1 p-0">
            <img
              src={
                AppConfig.CDN_Media_url + "b1b871802f6048caa74336bf068322cb.svg"
              }
              className="w-12px mb-2"
            />
          </div>
          <div className="col-11 p-0">
            <div className="row p-0 pb-2 align-items-center">
              <div className="col-7 p-0 od-prod-name">Cup cake</div>
              <div className="col-1 p-0 od-qty">× 2</div>
              <div className="col-4 end p-0">
                <div className="strike pe-1 od-mrp">₹ 83.60</div>
                <div className="od-dark">₹ 70.00</div>
              </div>
            </div>
            <div className="row p-0 align-items-center pb-1">
              <div className="col-7 p-0 od-sub-prod">Cup cake</div>
              <div className="col-1 p-0 od-sub-prod">× 1</div>
              <div className="col-4 end p-0">
                <div className="od-sub-prod">₹ 23.00</div>
              </div>
            </div>
            <div className="row p-0 align-items-center pb-1">
              <div className="col-7 p-0 od-sub-prod">GST Amount</div>
              <div className="col-1 p-0 od-sub-prod">× 1</div>
              <div className="col-4 end p-0">
                <div className="od-sub-prod">₹ 2.00</div>
              </div>
            </div>
            <div className="od-prod-name pb-1">Addons:</div>
            <div className="row p-0 align-items-center pb-1">
              <div className="col-7 p-0 od-sub-prod">Choco Chips</div>
              <div className="col-1 p-0 od-sub-prod">× 1</div>
              <div className="col-4 end p-0">
                <div className="od-sub-prod">₹ 20.00</div>
              </div>
            </div>
            <hr className="os-hr my-2" />
            <div className="row p-0 align-items-center pb-1">
              <div className="col-6 p-0 od-sub-prod">Quantity</div>
              <div className="col-6 end p-0">
                <div className="od-sub-prod">2</div>
              </div>
            </div>
            <div className="row p-0 align-items-center pb-1">
              <div className="col-6 p-0 od-sub-prod">Sub Total Amount</div>
              <div className="col-6 end p-0">
                <div className="od-sub-prod">₹ 66.00</div>
              </div>
            </div>
            <div className="row p-0 align-items-center pb-1">
              <div className="col-6 p-0 od-sub-prod">Total GST Amount</div>
              <div className="col-6 end p-0">
                <div className="od-sub-prod">₹ 4.00</div>
              </div>
            </div>
            <div className="row p-0 align-items-center pb-1">
              <div className="col-6 p-0 os-dark">Item Total</div>
              <div className="col-6 end p-0">
                <div className="os-dark">₹ 70.00</div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="bg-fff mb-2 p-3">
        <fieldset className="">
          <legend className="legendLeft ">
            <h5 className="fw-bold">Delivery</h5>
          </legend>
          <div className="p-3 pb-2">
            <div className="d-flex py-2 border-bottom">
              <div className="text-center w-30px">
                <img
                  src={
                    AppConfig.CDN_Image_url +
                    "a59e1c0623e7fcdc15369a8953a5f43c.png"
                  }
                  className="w-10 mt-1"
                />
              </div>
              <div className="ps-2">
                <p className="v-small ">Type</p>
                <p className="text-dark celias-font">
                  {/* {order_details.delivery_type}{" "} */}
                  Hyperlocal
                </p>
              </div>
            </div>
            {/* company */}
            {/* {Object.keys(
                          order_details.pickup_drop.hyperlocal_delivery
                        ).length > 0 ? ( */}
            <div className="d-flex py-2 border-bottom">
              {/* {order_details.pickup_drop.hyperlocal_delivery
                              .company_logo != "" ? ( */}
              {/* <img
                                src={AppHelper.imageSrc(
                                  context.app_data.image_base_url,
                                  order_details.pickup_drop.hyperlocal_delivery
                                    .company_logo,
                                  {
                                    format: "webp",
                                    width: 320,
                                  }
                                )}
                                // src={require("../../../assets/img/logo.png")}
                                className="shipment-img"
                              /> */}
              {/* ) : ( */}
              <div className="text-center w-30px">
                <img
                  src={
                    AppConfig.CDN_Media_url +
                    "47b953d50c14ae05d5f649d8ba23bd28.svg"
                  }
                  className="w-10"
                />
              </div>

              {/* )} */}

              <div className="ps-2">
                <p className="v-small">Company</p>
                <p className="text-dark celias-font">
                  SuperApp Delivery
                  {/* {
                                  order_details.pickup_drop.hyperlocal_delivery
                                    .company_name
                                } */}
                </p>
              </div>
            </div>
            {/* ) : null} */}

            {/* instruction */}
            {/* {order_details.instructions != null ||
                        order_details.instructions != "" ? ( */}
            <div className="d-flex py-2 border-bottom align-items-center">
              <div className="text-center w-30px">
                <img
                  src={
                    AppConfig.CDN_Media_url +
                    "9a595e405a3f7a8a1a4da3de09dc6c9c.svg"
                  }
                  className="w-20 mt-1"
                />
              </div>
              <div className="ps-2">
                <p className="v-small">Instruction</p>
                <p className="text-dark celias-font">
                  Careful to carry a products
                  {/* {order_details.instructions} */}
                </p>
              </div>
            </div>

            {/* executive */}
            {/* {Object.keys(
                          order_details.pickup_drop.hyperlocal_delivery
                        ).length > 0 ? ( */}
            <div className="d-flex py-2 align-items-center">
              {/* {order_details.pickup_drop.hyperlocal_delivery
                              .delivery_partner_dp != "" ||
                            order_details.pickup_drop.hyperlocal_delivery
                              .delivery_partner_dp != null ? (
                              <img
                                src={
                                  order_details.pickup_drop.hyperlocal_delivery
                                    .delivery_partner_dp
                                }
                                className="shipment-img"
                              />
                            ) : ( */}
              <div className="text-center w-30px">
                <img
                  src={
                    AppConfig.CDN_Media_url +
                    "d112a6cd7325a2ce2b456d5f22baea4a.jpg"
                  }
                  className="shipment-img"
                />
              </div>
              {/* )} */}

              <div className="ps-2">
                <p className="v-small">Executive</p>
                <p className="text-dark celias-font">
                  {/* {
                                  order_details.pickup_drop.hyperlocal_delivery
                                    .delivery_partner_name
                                } */}
                  Delivery Person
                </p>
              </div>
              <div className="p-0 end ms-auto">
                <div className="os-icon shadow center">
                  <img
                    src={
                      AppConfig.CDN_Media_url +
                      "a78b858bd49bf56c9fa495b641269677.svg"
                    }
                    className="w-20px"
                  />
                </div>
                <div className="ms-3 os-icon shadow center">
                  <img
                    src={
                      AppConfig.CDN_Media_url +
                      "ce8b7d884ffdb51228e7577cc36f38c0.svg"
                    }
                    className="w-20px"
                  />
                </div>
              </div>
            </div>
            {/* ) : null} */}

            <div className="py-2">
              <div className="os-rr-div center">
                <img
                  src={
                    AppConfig.CDN_Media_url +
                    "120594404ffd4bed790f9e1e9329b0d2.svg"
                  }
                  className="w-16px"
                />
                <div className="os-dark ps-2">Order Tracking</div>
              </div>
            </div>

            {/* ) : null} */}
          </div>
        </fieldset>
      </div>

      <div className="bg-fff mb-2 p-3">
        <div className="d-flex align-items-center pb-2">
          <div className="od-sub-prod">Subtotal</div>
          <div className="ms-auto">
            <div className="od-sub-prod">₹ 66.00</div>
          </div>
        </div>
        <div className="d-flex align-items-center pb-2">
          <div className="od-sub-prod">GST</div>
          <div className="ms-auto">
            <div className="od-sub-prod">₹ 4.00</div>
          </div>
        </div>
        <div className="d-flex align-items-center pb-2">
          <div className="od-sub-prod">Delivery fee (4.5 km)</div>
          <div className="ms-auto">
            <div className="od-sub-prod">₹ 20.00</div>
          </div>
        </div>
        <div className="d-flex align-items-center pb-2">
          <div className="od-sub-prod">Convenience fee</div>
          <div className="ms-auto">
            <div className="od-sub-prod">₹ 11.00</div>
          </div>
        </div>
        <hr className="os-hr my-2" />
        <div className="d-flex align-items-center pb-2">
          <div className="os-dark">Total Amount</div>
          <div className="ms-auto">
            <div className="os-dark">₹ 101.00</div>
          </div>
        </div>
      </div>

      <div className="bg-fff mb-2 p-3">
        <fieldset className="">
          <legend className="legendLeft od-head">
            <h5 className="fw-bold">Payment</h5>
          </legend>
          <div className="p-3 pb-2">
            <div className="d-flex py-2 border-bottom">
              <div className="text-center w-30px">
                <img
                  src={
                    AppConfig.CDN_Media_url +
                    "e06c2b11d7f55d8c015fa9bc5b8d6674.svg"
                  }
                  className="w-10 mt-1"
                />
              </div>
              <div className="ps-2">
                <p className="v-small ">Mode</p>
                <p className="text-dark celias-font">
                  {/* {order_details.delivery_type}{" "} */}
                  Pay on Delivery
                </p>
              </div>
            </div>
            {/* company */}
            {/* {Object.keys(
                          order_details.pickup_drop.hyperlocal_delivery
                        ).length > 0 ? ( */}
            <div className="d-flex py-2 ">
              {/* {order_details.pickup_drop.hyperlocal_delivery
                              .company_logo != "" ? ( */}
              {/* <img
                                src={AppHelper.imageSrc(
                                  context.app_data.image_base_url,
                                  order_details.pickup_drop.hyperlocal_delivery
                                    .company_logo,
                                  {
                                    format: "webp",
                                    width: 320,
                                  }
                                )}
                                // src={require("../../../assets/img/logo.png")}
                                className="shipment-img"
                              /> */}
              {/* ) : ( */}
              <div className="text-center w-30px">
                <img
                  src={
                    AppConfig.CDN_Media_url +
                    "5af5ea59540ed7e1e2b455573b95dced.svg"
                  }
                  className="w-10"
                />
              </div>

              {/* )} */}

              <div className="ps-2">
                <p className="v-small">Status</p>
                <p className="text-dark celias-font">
                  Not yet Paid
                  {/* {
                                  order_details.pickup_drop.hyperlocal_delivery
                                    .company_name
                                } */}
                </p>
              </div>
            </div>
            {/* ) : null} */}
          </div>
        </fieldset>
      </div>
      <div className="bg-fff mb-2 p-3">
        <h5 className="mb-3 fw-bold">Order Actions</h5>
        <div className="d-flex align-items-center">
          <img
            src={
              AppConfig.CDN_Media_url + "e0b0f3cd264ce2c3773572cb25bc1590.svg"
            }
            className="w-14px"
          />
          <div className="os-dark capitalize ps-2">Cancel Orders</div>
        </div>
      </div>

      <div className="bg-fff mb-2 p-3">
        <div className="d-flex align-items-center pb-2">
          <div className="os-dark">Refund</div>
          <div className="d-flex align-items-center ps-2">
            <img
              src={
                AppConfig.CDN_Media_url + "58b18713d1577491802b06ac3c3bfd41.svg"
              }
              className="w-16px"
            />
            <div className="os-status ps-1">Complete</div>
          </div>
        </div>
        <div className="d-flex align-items-center pb-2">
          <div className="os-dark pe-1">To:</div>
          <div className="os-instra">UPI</div>
        </div>
        <div className="d-flex align-items-center pb-2">
          <div className="os-dark pe-1">Expected by:</div>
          <div className="os-dark">20 Aug, 2024</div>
          <div className="ms-auto os-dark">₹ 569.00</div>
        </div>

        <div className="order-track">
          <div className="od-track-step">
            <div className="order-track-status">
              <span className="order-track-status-dot completed"></span>
              <span className="order-track-status-line completed"></span>
            </div>
            <div className="order-track-text ps-1">
              <div className=" pb-1 cursor os-dark">
                Superapp has initiated your refund
              </div>
              <div className="od-sub-prod pb-1">Completed</div>
              <div className="od-sub-prod pb-1">20 Aug, 2024</div>
            </div>
          </div>

          <div className="od-track-step">
            <div className="order-track-status">
              <span className="order-track-status-dot completed"></span>
              <span className="order-track-status-line completed"></span>
            </div>
            <div className="order-track-text ps-1">
              <div className=" pb-1 cursor os-dark">
                Your bank has processed your refund
              </div>
              <div className="od-sub-prod pb-1">Completed</div>
              <div className="od-sub-prod pb-1">20 Aug, 2024</div>
            </div>
          </div>

          <div className="od-track-step">
            <div className="order-track-status">
              <span className="order-track-status-dot completed"></span>
              <span className="order-track-status-line completed"></span>
            </div>
            <div className="order-track-text ps-1">
              <div className=" pb-1 cursor os-dark">
                Refund credited to your account
              </div>
              <div className="od-sub-prod pb-1">
                Completed - The refund amouint should reflect in your account by
                now. If there is an issue, please contact your bank's customer
                care. Your RRN is:
                <span className="text-red ps-1">414287209092</span>
              </div>
              <div className="d-flex align-items-center pt-1">
                <div className="od-sub-prod ">20 Aug, 2024</div>
                <div className="od-sub-prod text-prime1 ps-1">What is RRN?</div>
              </div>
              <div className="pt-1 os-light">
                Last Updated: 4:23 PM, 20 Aug, 2024
              </div>
            </div>
          </div>
        </div>

        <hr className="mt-2 os-hr" />
        <div className="row p-0 align-items-center">
          <div className="col-8 p-0">
            <div className="os-dark">Did you receive your Refund?</div>
          </div>
          <div className="col-4 end">
            <div className="border od-sub-prod text-red py-1 px-3">YES</div>
            <div className="border od-sub-prod text-red py-1 px-3 ms-2">NO</div>
          </div>
        </div>
      </div>

      {/* <div className="bottom-fixed os-rr-div row w-100 p-3 align-items-center">
      <div className="col-6 os-rr-border center">
              <img
                src={
                  AppConfig.CDN_Media_url +
                  "10e42eefca20e05c90acd690188fc6be.svg"
                }
                className="w-12px"
              />
              <div className="os-dark ps-1 fs-7">Rate Order</div>
            </div>
            <div className="col-6 center">
              <img
                src={
                  AppConfig.CDN_Media_url +
                  "624d15ae439e017f3938d44e903a2823.svg"
                }
                className="w-16px"
              />
              <div className="os-dark ps-1 fs-7">Reorder</div>
            </div>
      </div> */}

      <div className="bg-fff mb-2">
        <div className="d-flex align-items-center  p-2">
          <div className="od-id">#181</div>
          <div className="ms-auto d-flex align-items-center">
            <div className="od-status-circle green-bg-clr"></div>
            <div className="od-status ms-1 green-clr">Delivered</div>
          </div>
        </div>
        <div className="o-date p-2 pt-0">17-08-24 03:27pm</div>
      </div>
      <div className="bg-fff mb-2 p-2">
        <div className="shop-div row align-items-center">
          <div className="col-2 p-0">
            <img
              src={
                AppConfig.CDN_Image_url + "aa5ea240d88e138ba9158c523842b4fd.jpg"
              }
              className="os-img"
            />
          </div>
          <div className="col-7 d-flex p-0">
            <div className="line-h-17px">
              <div className="os-name">SuperApp Delivery</div>
              <div className="os-sta pt-1 ps-0">Pickup & Drop</div>
            </div>
          </div>
          <div className="col-3 p-0 end">
            <div className="os-icon shadow center">
              <img
                src={
                  AppConfig.CDN_Media_url +
                  "a78b858bd49bf56c9fa495b641269677.svg"
                }
                className="w-20px"
              />
            </div>
            <div className="ms-3 os-icon shadow center">
              <img
                src={
                  AppConfig.CDN_Media_url +
                  "ce8b7d884ffdb51228e7577cc36f38c0.svg"
                }
                className="w-20px"
              />
            </div>
          </div>
        </div>
      </div>

      <div className="bg-fff p-2 mb-2">
        <div className="card p-2 mb-2 shadow radius-10">
          <div className="row p-0">
            <div className="col-1 p-0">
              <img
                src={
                  AppConfig.CDN_Image_url +
                  "69e22d8519c6739a9feceef2d109c256.png"
                }
                className="w-20px"
              />
            </div>
            <div className="col-8 p-0">
              <div className="d-flex align-items-center pb-1">
                <div className="os-dark pe-1">Pickup</div>
                <div className="os-light pe-1">from</div>
                <div className="os-dark uppercase">Home</div>
              </div>
              <div className="os-rate">Person 1</div>
              <div className="os-pname ps-0">
                Chennai - Theni Highway, Endapuli KamatchiPuram, Theni, Tamil
                Nadu, India, 625604
              </div>
            </div>
            <div className="col-3 p-0 end">
              <div className="os-icon shadow center">
                <img
                  src={
                    AppConfig.CDN_Media_url +
                    "a78b858bd49bf56c9fa495b641269677.svg"
                  }
                  className="w-20px"
                />
              </div>
              <div className="ms-3 os-icon shadow center">
                <img
                  src={
                    AppConfig.CDN_Media_url +
                    "ce8b7d884ffdb51228e7577cc36f38c0.svg"
                  }
                  className="w-20px"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="card p-2 shadow radius-10 mb-2">
          <div className="row p-0">
            <div className="col-1 p-0">
              <img
                src={
                  AppConfig.CDN_Image_url +
                  "2f0620ddc47a95162db3b88a35358317.png"
                }
                className="w-20px"
              />
            </div>
            <div className="col-8 p-0">
              <div className="d-flex align-items-center pb-1">
                <div className="os-dark pe-1">Delivery</div>
                <div className="os-light pe-1">to</div>
                <div className="os-dark uppercase">Office</div>
              </div>
              <div className="os-rate">Person 1</div>
              <div className="os-pname ps-0">
                Mary Matha College Road, Theni, Tamil Nadu, India, 625604
              </div>
            </div>
            <div className="col-3 p-0 end">
              <div className="os-icon shadow center">
                <img
                  src={
                    AppConfig.CDN_Media_url +
                    "a78b858bd49bf56c9fa495b641269677.svg"
                  }
                  className="w-20px"
                />
              </div>
              <div className="ms-3 os-icon shadow center">
                <img
                  src={
                    AppConfig.CDN_Media_url +
                    "ce8b7d884ffdb51228e7577cc36f38c0.svg"
                  }
                  className="w-20px"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="bg-fff mb-2 p-3">
        <fieldset className="">
          <legend className="legendLeft od-head">
            <h5 className="fw-bold">Package Details</h5>
          </legend>
          <div className="p-3 pb-2">
            <div className="d-flex py-2 border-bottom">
              <div className="text-center w-30px">
                <img
                  src={
                    AppConfig.CDN_Media_url +
                    "988a5ec099af81658c9b3aa32f712178.svg"
                  }
                  className="w-10 mt-1"
                />
              </div>
              <div className="ps-2">
                <p className="v-small ">Package Content</p>
                <p className="text-dark celias-font">
                  {/* {order_details.delivery_type}{" "} */}
                  Books
                </p>
              </div>
            </div>
            {/* company */}
            {/* {Object.keys(
                          order_details.pickup_drop.hyperlocal_delivery
                        ).length > 0 ? ( */}
            <div className="d-flex py-2 ">
              {/* {order_details.pickup_drop.hyperlocal_delivery
                              .company_logo != "" ? ( */}
              {/* <img
                                src={AppHelper.imageSrc(
                                  context.app_data.image_base_url,
                                  order_details.pickup_drop.hyperlocal_delivery
                                    .company_logo,
                                  {
                                    format: "webp",
                                    width: 320,
                                  }
                                )}
                                // src={require("../../../assets/img/logo.png")}
                                className="shipment-img"
                              /> */}
              {/* ) : ( */}
              <div className="text-center w-30px">
                <img
                  src={
                    AppConfig.CDN_Media_url +
                    "9d9fa1bd6664b2548775dc3264185365.svg"
                  }
                  className="w-10"
                />
              </div>

              {/* )} */}

              <div className="ps-2">
                <p className="v-small">Package Value</p>
                <p className="text-dark celias-font">
                  ₹ 550
                  {/* {
                                  order_details.pickup_drop.hyperlocal_delivery
                                    .company_name
                                } */}
                </p>
              </div>
            </div>
            {/* ) : null} */}
          </div>
        </fieldset>
      </div>

      <div className="bg-fff mb-2 p-3">
        <fieldset className="">
          <legend className="legendLeft ">
            <h5 className="fw-bold">Delivery</h5>
          </legend>
          <div className="p-3 pb-2">
            <div className="d-flex py-2 border-bottom">
              <div className="text-center w-30px">
                <img
                  src={
                    AppConfig.CDN_Image_url +
                    "a59e1c0623e7fcdc15369a8953a5f43c.png"
                  }
                  className="w-10 mt-1"
                />
              </div>
              <div className="ps-2">
                <p className="v-small ">Type</p>
                <p className="text-dark celias-font">
                  {/* {order_details.delivery_type}{" "} */}
                  Hyperlocal
                </p>
              </div>
            </div>
            {/* company */}
            {/* {Object.keys(
                          order_details.pickup_drop.hyperlocal_delivery
                        ).length > 0 ? ( */}
            <div className="d-flex py-2 border-bottom">
              {/* {order_details.pickup_drop.hyperlocal_delivery
                              .company_logo != "" ? ( */}
              {/* <img
                                src={AppHelper.imageSrc(
                                  context.app_data.image_base_url,
                                  order_details.pickup_drop.hyperlocal_delivery
                                    .company_logo,
                                  {
                                    format: "webp",
                                    width: 320,
                                  }
                                )}
                                // src={require("../../../assets/img/logo.png")}
                                className="shipment-img"
                              /> */}
              {/* ) : ( */}
              <div className="text-center w-30px">
                <img
                  src={
                    AppConfig.CDN_Media_url +
                    "47b953d50c14ae05d5f649d8ba23bd28.svg"
                  }
                  className="w-10"
                />
              </div>

              {/* )} */}

              <div className="ps-2">
                <p className="v-small">Company</p>
                <p className="text-dark celias-font">
                  SuperApp Delivery
                  {/* {
                                  order_details.pickup_drop.hyperlocal_delivery
                                    .company_name
                                } */}
                </p>
              </div>
            </div>
            {/* ) : null} */}

            {/* instruction */}
            {/* {order_details.instructions != null ||
                        order_details.instructions != "" ? ( */}
            <div className="d-flex py-2 border-bottom align-items-center">
              <div className="text-center w-30px">
                <img
                  src={
                    AppConfig.CDN_Media_url +
                    "9a595e405a3f7a8a1a4da3de09dc6c9c.svg"
                  }
                  className="w-20 mt-1"
                />
              </div>
              <div className="ps-2">
                <p className="v-small">Instruction</p>
                <p className="text-dark celias-font">
                  Careful to carry a products
                  {/* {order_details.instructions} */}
                </p>
              </div>
            </div>

            {/* executive */}
            {/* {Object.keys(
                          order_details.pickup_drop.hyperlocal_delivery
                        ).length > 0 ? ( */}
            <div className="d-flex py-2 align-items-center">
              {/* {order_details.pickup_drop.hyperlocal_delivery
                              .delivery_partner_dp != "" ||
                            order_details.pickup_drop.hyperlocal_delivery
                              .delivery_partner_dp != null ? (
                              <img
                                src={
                                  order_details.pickup_drop.hyperlocal_delivery
                                    .delivery_partner_dp
                                }
                                className="shipment-img"
                              />
                            ) : ( */}
              <div className="text-center w-30px">
                <img
                  src={
                    AppConfig.CDN_Media_url +
                    "d112a6cd7325a2ce2b456d5f22baea4a.jpg"
                  }
                  className="shipment-img"
                />
              </div>
              {/* )} */}

              <div className="ps-2">
                <p className="v-small">Executive</p>
                <p className="text-dark celias-font">
                  {/* {
                                  order_details.pickup_drop.hyperlocal_delivery
                                    .delivery_partner_name
                                } */}
                  Delivery Person
                </p>
              </div>
              <div className="p-0 end ms-auto">
                <div className="os-icon shadow center">
                  <img
                    src={
                      AppConfig.CDN_Media_url +
                      "a78b858bd49bf56c9fa495b641269677.svg"
                    }
                    className="w-20px"
                  />
                </div>
                <div className="ms-3 os-icon shadow center">
                  <img
                    src={
                      AppConfig.CDN_Media_url +
                      "ce8b7d884ffdb51228e7577cc36f38c0.svg"
                    }
                    className="w-20px"
                  />
                </div>
              </div>
            </div>
            {/* ) : null} */}

            <div className="py-2">
              <div className="os-rr-div center">
                <img
                  src={
                    AppConfig.CDN_Media_url +
                    "120594404ffd4bed790f9e1e9329b0d2.svg"
                  }
                  className="w-16px"
                />
                <div className="os-dark ps-2">Order Tracking</div>
              </div>
            </div>

            {/* ) : null} */}
          </div>
        </fieldset>
      </div>

      <div className="bg-fff mb-2 p-3">
        <div className="d-flex align-items-center pb-2">
          <div className="od-sub-prod">Subtotal</div>
          <div className="ms-auto">
            <div className="od-sub-prod">₹ 66.00</div>
          </div>
        </div>
        <div className="d-flex align-items-center pb-2">
          <div className="od-sub-prod">GST</div>
          <div className="ms-auto">
            <div className="od-sub-prod">₹ 4.00</div>
          </div>
        </div>
        <div className="d-flex align-items-center pb-2">
          <div className="od-sub-prod">Delivery fee (4.5 km)</div>
          <div className="ms-auto">
            <div className="od-sub-prod">₹ 20.00</div>
          </div>
        </div>
        <div className="d-flex align-items-center pb-2">
          <div className="od-sub-prod">Convenience fee</div>
          <div className="ms-auto">
            <div className="od-sub-prod">₹ 11.00</div>
          </div>
        </div>
        <hr className="os-hr my-2" />
        <div className="d-flex align-items-center pb-2">
          <div className="os-dark">Total Amount</div>
          <div className="ms-auto">
            <div className="os-dark">₹ 101.00</div>
          </div>
        </div>
      </div>

      <div className="bg-fff mb-5 p-3">
        <fieldset className="">
          <legend className="legendLeft od-head">
            <h5 className="fw-bold">Payment</h5>
          </legend>
          <div className="p-3 pb-2">
            <div className="d-flex py-2 border-bottom">
              <div className="text-center w-30px">
                <img
                  src={
                    AppConfig.CDN_Media_url +
                    "e06c2b11d7f55d8c015fa9bc5b8d6674.svg"
                  }
                  className="w-10 mt-1"
                />
              </div>
              <div className="ps-2">
                <p className="v-small ">Mode</p>
                <p className="text-dark celias-font">
                  {/* {order_details.delivery_type}{" "} */}
                  Pay on Delivery
                </p>
              </div>
            </div>
            {/* company */}
            {/* {Object.keys(
                          order_details.pickup_drop.hyperlocal_delivery
                        ).length > 0 ? ( */}
            <div className="d-flex py-2 ">
              {/* {order_details.pickup_drop.hyperlocal_delivery
                              .company_logo != "" ? ( */}
              {/* <img
                                src={AppHelper.imageSrc(
                                  context.app_data.image_base_url,
                                  order_details.pickup_drop.hyperlocal_delivery
                                    .company_logo,
                                  {
                                    format: "webp",
                                    width: 320,
                                  }
                                )}
                                // src={require("../../../assets/img/logo.png")}
                                className="shipment-img"
                              /> */}
              {/* ) : ( */}
              <div className="text-center w-30px">
                <img
                  src={
                    AppConfig.CDN_Media_url +
                    "5af5ea59540ed7e1e2b455573b95dced.svg"
                  }
                  className="w-10"
                />
              </div>

              {/* )} */}

              <div className="ps-2">
                <p className="v-small">Status</p>
                <p className="text-dark celias-font">
                  Not yet Paid
                  {/* {
                                  order_details.pickup_drop.hyperlocal_delivery
                                    .company_name
                                } */}
                </p>
              </div>
            </div>
            {/* ) : null} */}
          </div>
        </fieldset>
      </div>

      <div className="bottom-fixed os-rr-div row w-100 p-3">
        <div className="center">
          <img
            src={
              AppConfig.CDN_Media_url + "10e42eefca20e05c90acd690188fc6be.svg"
            }
            className="w-12px"
          />
          <div className="os-dark fs-7 ps-1 text-dark">Rate Order</div>
        </div>
      </div>

   
    </div>
  );
}
