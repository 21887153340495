import React, { useState, useContext, useEffect, useRef } from "react";
import { AppHelper } from "../../utils/Helper";
import { AppConfig } from "../../config";
import DataContext from "../../utils/Context/DataContext";
import { Dialog, makeStyles, Modal } from "@material-ui/core";
import { api } from "../../utils/Api";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { useNavigate } from "react-router-dom";
import bootstrap from "../../assets/libraries/bootstrap/js/bootstrap";
import MapLocationPickModal from "../../pages/Common/Address/MapLocationPickModal";
import { mobileApp } from "../../utils/MobileApp";

const useStyles = makeStyles((theme) => ({
  root: {
    [theme.breakpoints.down("xs")]: {
      alignItems: "flex-end", // push the dialog to bottom
    },
  },
  paper: {
    // make the content full width
    [theme.breakpoints.down("xs")]: {
      margin: 0,
      maxWidth: "100%",
      width: "100%",
      overflow: "scroll",
      borderTopLeftRadius: 20,
      borderTopRightRadius: 20,
      paddingBottom: "45px",
    },
    overflow: "scroll",
    width: "600px",
  },
}));

function LocationAutoCompleteComponent(props: any) {
  const context: any = useContext(DataContext);
  const [address_list, SetAddressList] = useState([]);
  const [close_data, SetCloseData] = useState({
    action: "close",
  });
  const [map_lp_modal, SetMapLPModal] = useState({
    is_open: false,
    from: "",
    function: "",
    location_data: {},
    place_data: {},
  });
  const [searchTerm, SetSearchTerm] = useState("");
  const [search_places, SetSearchPlaces] = useState([]);
  const [map, setMap] = useState(null); // Needed for PlacesService
  const [location_loading, SetLocationLoading] = useState(false);
  const [continue_loading, SetContinueLoading] = useState(false);
  const Spinner = () => (
    <div className="vendor-list-loader-spinner mx-auto"></div>
  );
  const searchInputRef: any = useRef(null);

  let LocationACModal: any;

  useEffect(() => {
    console.log("auto complete On mount :", props);
    // let AutoCompleteListmyModal1: any =
    //   document.getElementById("AutoCompleteList");
    // AutoCompleteListmyModal1.addEventListener(
    //   "hidden.bs.modal",
    //   function (event: any) {
    //     console.log("event :", event);
    //     let close_data = {
    //       from: props.from,
    //       function: props.function,
    //       location_data: props.location_data,
    //       place_data: props.place_data,
    //     };
    //     props.close(close_data);
    //   }
    // );

    let send_data = props.place_data.name;
    SetSearchTerm(send_data);
    // handleSearchInputChange(send_data);
    let push_data: any = [];
    push_data.push(props.place_data);
    console.log("push_data :", push_data);
    SetSearchPlaces(push_data);
  }, []);

  useEffect(() => {
    // Load the Google PlacesService once the map is available
    if (window.google && searchInputRef.current && !map) {
      const dummyMapDiv = document.createElement("div");
      const tempMap: any = new window.google.maps.Map(dummyMapDiv); // Create a dummy map to initialize PlacesService
      setMap(tempMap);
    }
  }, [map]);

  useEffect(() => {
    if (props.open) {
      console.log("Model LocationAutoCompleteComponent On props.open :", props);

      // LocationACModal = new bootstrap.Modal(
      //   document.getElementById("AutoCompleteList"),
      //   {}
      // );
      // LocationACModal.show();

      //   searchInputRef.current =
      //     props.location_data.lat + "," + props.location_data.lng;

      // let send_data = props.location_data.lat + "," + props.location_data.lng;
      // SetSearchTerm(send_data);

      let send_data = props.place_data.name;
      SetSearchTerm(send_data);
      // handleSearchInputChange(send_data);
      let push_data: any = [];
      push_data.push(props.place_data);
      console.log("push_data :", push_data);
      SetSearchPlaces(push_data);

      //   let send_data = {
      //     key: "delivery_address",
      //   };
      //   if (context.is_data_available(send_data)) {
      //     SetAddressList(context.app_data.delivery_address);
      //     SetFilteredAddressList(context.app_data.delivery_address);
      //     SetIsAddressLoading(false);
      //   } else {
      //     get_delivery_address();
      //   }
    }
  }, [props.open]);

  //   useEffect(() => {
  //     if (searchTerm.length >= 3) {
  //       const results = address_list.filter((list: any) =>
  //         list.name.toLowerCase().includes(searchTerm.toLowerCase())
  //       );
  //       SetFilteredAddressList(results);
  //     } else {
  //       if (searchTerm.length == 0) {
  //         SetFilteredAddressList([]);
  //         SetSearchPlaces([]);
  //       }
  //     }
  //   }, [searchTerm]);

  const handleSearchInputChange = (get_data: any) => {
    console.log("handleSearchInputChange get_data :", get_data);
    console.log("handleSearchInputChange map :", map);

    // const query = searchInputRef.current.value;
    const query = get_data;
    console.log("handleSearchInputChange query :", query);

    if (query && map) {
      const service = new window.google.maps.places.PlacesService(map);

      const request = {
        query: query,
        // fields: ["name", "formatted_address"],
        fields: ["formatted_address", "geometry", "name"],
        strictBounds: false,
        types: ["establishment"],
      };
      console.log("request :", request);
      service.textSearch(request, (results: any, status: any) => {
        console.log("service.textSearch results :", results);
        console.log("service.textSearch status :", status);

        if (status === window.google.maps.places.PlacesServiceStatus.OK) {
          SetSearchPlaces(results);
        } else {
          SetSearchPlaces([]);
        }
      });
    } else {
      SetSearchPlaces([]);
    }
  };

  const SearchResultList = (srl_props: any) => {
    return (
      <div className="mt-2 mb-5">
        <div className="sub-head-text">Search result</div>
        {search_places.map((place: any, index: any) => (
          <div
            className="mt-2 mb-1 card shadow radius-10 px-3 py-2"
            key={index}
          >
            <div
              className="py-2 recent_search border-bottom"
              onClick={() => {
                console.log("place :", place);
                // let lat = place.geometry.location.lat();
                // let lng = place.geometry.location.lng();
                // SetMapLPModal({
                //   is_open: true,
                //   from: "search",
                //   function: "no_function",
                //   location_data: {
                //     lat: lat,
                //     lng: lng,
                //   },
                //   place_data: place,
                // });
                let close_data = {
                  action: "save",
                  from: props.from,
                  function: props.function,
                  location_data: {
                    lat: place.geometry.location.lat(),
                    lng: place.geometry.location.lng(),
                  },
                  place_data: place,
                };
                props.onClose(close_data);
              }}
            >
              <div className="d-flex align-items-center ">
                <img
                  src={
                    AppConfig.CDN_Media_url +
                    "99fd4a0eb6be0e5a4d40b13df49e7b3e.svg"
                  }
                  className="w-14px"
                />
                <div className="ps-2 loc-add">{place.name}</div>
              </div>
              <div className="pt-1 sub-head-text fs-12px">
                {place.formatted_address}
              </div>
            </div>
          </div>
        ))}
      </div>
    );
  };

  const SearchResultListSkeleton = (srl_props: any) => {
    return (
      <div className="mt-2 mb-5">
        <div className="sub-head-text">Search result</div>
        {[1, 2, 3, 4].map((place: any, index: any) => (
          <div
            className="mt-2 mb-1 card shadow radius-10 px-3 py-2"
            key={index}
          >
            <div className="py-2 recent_search border-bottom">
              <div className="d-flex align-items-center ">
                {/* <img
                  src={
                    AppConfig.CDN_Media_url +
                    "99fd4a0eb6be0e5a4d40b13df49e7b3e.svg"
                  }
                  className="w-14px"
                />
                <div className="ps-2 loc-add">{place.name}</div> */}
                <Skeleton height="15px" width="20%" />
                <Skeleton height="15px" width="80%" />
              </div>
              <div className="pt-1 sub-head-text fs-12px">
                <Skeleton height="12px" width="100%" />
                <Skeleton height="12px" width="50%" />
              </div>
            </div>
          </div>
        ))}
      </div>
    );
  };

  function getLocation() {
    document.addEventListener("message", putLocationFromMobile);
    if (context.app_data.application.platform == "web") {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(showPosition, errorCallback);
      }
    } else if (
      context.app_data.application.platform == "android" ||
      context.app_data.application.platform == "ios"
    ) {
      mobileApp({
        function: "getlocationlatlng",
        app_type: "react_web",
        accuracy: "Lowest",
      });
    }
  }

  function putLocationFromMobile(message: any) {
    SetLocationLoading(false);
    SetContinueLoading(false);
    if (message.data.function == "getlocationlatlng") {
      // if (message.data.status == "success") {
      if (
        message.data.lat != null &&
        message.data.lng != null &&
        message.data.lat != undefined &&
        message.data.lng != undefined
      ) {
        let lat = message.data.lat;
        let lng = message.data.lng;
        // SetMapLPModal({
        //   is_open: true,
        //   from: "add_address",
        //   function: "get_current_location",
        //   location_data: {
        //     lat: lat,
        //     lng: lng,
        //   },
        //   place_data: {
        //     formatted_address: "",
        //   },
        // });
        let close_data = {
          action: "save",
          from: props.from,
          function: props.function,
          location_data: {
            lat: lat,
            lng: lng,
          },
          place_data: {
            formatted_address: "",
          },
        };
        props.onClose(close_data);
      } else {
        // map.setCenter({ lat: "11.3410", lng: "77.7172" });
        alert("Enable Location");
      }
    }

    document.removeEventListener("message", () => {});
  }

  function showPosition(
    position: any,
    placeLocationMarker: any = true,
    placeMarker: any = false
  ) {
    SetLocationLoading(false);
    SetContinueLoading(false);
    let lat = position.coords.latitude;
    let lng = position.coords.longitude;
    // SetMapLPModal({
    //   is_open: true,
    //   from: "add_address",
    //   function: "get_current_location",
    //   location_data: {
    //     lat: lat,
    //     lng: lng,
    //   },
    //   place_data: {
    //     formatted_address: "",
    //   },
    // });
    let close_data = {
      action: "save",
      from: props.from,
      function: props.function,
      location_data: {
        lat: lat,
        lng: lng,
      },
      place_data: {
        formatted_address: "",
      },
    };
    props.onClose(close_data);
  }

  function errorCallback(error: any) {
    alert("Enable Location");
    SetLocationLoading(false);
    SetContinueLoading(false);
    console.log("error :", error);
    console.log("props :", props);
  }

  return (
    <>
      {/* <div
        className="modal fade"
        id="AutoCompleteList"
        tabIndex={-1}
        aria-labelledby="ModalLabel"
        aria-hidden="true"
      > */}
      {/* <div className="modal-dialog modal-fullscreen-sm-down"> */}
      {/* <div className="modal-content"> */}
      <div className="scrolling-card overflowY-max card p-0">
        <div className="">
          {/* header */}
          <div className="header header-fixed-top header-badge-padd border-bottom-1 d-flex align-items-center">
            <div
              className="ps-1 pe-2 cursor"
              onClick={() => {
                props.onClose({
                  action:"close"
                });
              }}
            >
              <img
                src={
                  AppConfig.CDN_Media_url +
                  "30549c56761b976b23418471a0f0037a.svg"
                }
                className="w-14px"
              />
            </div>
            <div className="ps-1 pe-0 w-100">
              <div className="loc-head">Select a location</div>
            </div>
          </div>
          {/* header */}
          {/* <div className="modal-header shadow border-none">
              <div className="ps-1 pe-2 cursor" data-bs-dismiss="modal">
                <img
                  src={
                    AppConfig.CDN_Media_url +
                    "30549c56761b976b23418471a0f0037a.svg"
                  }
                  className="w-14px"
                />
              </div>
              <div className="ps-1 pe-0 w-100">
                <div className="loc-head">Select a location</div>
              </div>
            </div> */}
          {/* <div className="modal-body bg-body-color "> */}
          <div className="custom-container mt-7 p-2 bg-body-colo">
            {/* search */}
            <div className="address-search-bar d-flex align-items-center">
              <img
                src={
                  AppConfig.CDN_Media_url +
                  "4075afa7aafecff54a5220213ad396f0.svg"
                }
                className="w-20px"
              />
              <div className="ps-2 ">
                <input
                  type="text"
                  // id={"search-input"}
                  ref={searchInputRef}
                  className=" custom-input-text"
                  placeholder="Enter location manually"
                  value={searchTerm}
                  onChange={(e: any) => {
                    if (e.target.value.length > 2) {
                      handleSearchInputChange(e.target.value);
                    }
                    console.log("e :", e);
                    SetSearchTerm(e.target.value);
                  }}
                />
              </div>
              <div className="ms-auto cursor ps-2">
                <img
                  src={
                    AppConfig.CDN_Media_url +
                    "d6da0c9c3517352622470b635f8a8d38.svg"
                  }
                  className="w-20px"
                  onClick={() => {
                    // handleSearchInputChange()
                    SetSearchTerm("");
                    SetSearchPlaces([]);
                  }}
                />
              </div>
            </div>
            {/* use my current location */}
            <div className="mt-3 card shadow p-3 radius-10">
              <div
                className="d-flex align-items-center"
                onClick={() => {
                  SetLocationLoading((prevalue) => {
                    if (!prevalue) {
                      getLocation();
                      SetContinueLoading(true);
                      prevalue = true;
                    } else {
                      console.log("location loading");
                    }
                    return prevalue;
                  });
                }}
              >
                <img
                  src={
                    AppConfig.CDN_Media_url +
                    "99fd4a0eb6be0e5a4d40b13df49e7b3e.svg"
                  }
                  className="w-16px"
                />
                <div className="ps-3 loc-add">Use my current location</div>
                <div className="ms-auto pop-font fs-11px text-primary">
                  {location_loading && <Spinner />}
                </div>
              </div>
            </div>
            {/* search result */}
            {searchTerm != "" ||
            searchTerm.length > 3 ||
            search_places.length > 0 ? (
              <SearchResultList />
            ) : // <SearchResultListSkeleton />
            null}
          </div>
        </div>
        {/* </div> */}
        {/* </div> */}
        {/* </div> */}
      </div>

      {/* </div> */}
    </>
  );
}

export default LocationAutoCompleteComponent;
