import React, { useState, useEffect, useContext, Suspense } from "react";
import {
  Link,
  useParams,
  useOutletContext,
  useSearchParams,
} from "react-router-dom";
import ImageContainer from "../../../components/ImageContainer/ImageContainer";
import { api } from "../../../utils/Api";
import DataContext from "../../../utils/Context/DataContext";
import { AppHelper } from "../../../utils/Helper";
import {
  AllRestaurants,
  PopularFoodVarieties,
} from "../Dashboard/SkeletonDashboard";
import Cookies from "js-cookie";

import Search from "../Search/Search";

export default function VendorsCategoryPage(props: any) {
  const context: any = useContext(DataContext);
  const [vendors_list, SetVendorsList] = useState([]);
  const [id_value, SetIdValue] = useState(useParams());
  const [category_list, SetCategoryList] = useState([]);
  const [category_data, SetCategoryData] = useState({});
  const [outlet_context_data, SetOutletContextData]: any = useOutletContext();
  const [url_params, SetUrlParams] = useSearchParams();
  const [show_category_loader, SetShowCategoryLoader] = useState(false);
  const [show_loader, SetShowLoader] = useState(true);

  useEffect(() => {
    console.log("VendorsCategoryPage id value:", id_value);
    SetOutletContextData((prevValue: any) => {
      prevValue.have_back = props.have_back;
      prevValue.title = "Shop Name";
      prevValue.have_footer = true;
      // prevValue.link = {
      //   rel: 'canonical',
      //   href: 'http://mysite.com/example',
      // }
      return { ...prevValue };
    });
    // get_vendors();
    // get_categories();
  }, []);

  useEffect(() => {
    console.log("on change shipment_details");
    // get_vendors();
    // get_categories();
  }, [context.app_data.shipment_details]);

  useEffect(() => {
    console.log("query on change :", url_params.get("n"));
    // get_vendors();
    get_categories();
  }, [url_params.get("n")]);

  async function get_vendors() {
    let cat_value = [];
    // cat_value.push(id_value.category_slug);
    cat_value.push(url_params.get("n"));
    let lat_lang: any = {};
    if (Cookies.get("location") != undefined) {
      lat_lang = JSON.parse(Cookies.get("location"));
    }
    // lat_lang = context.app_data.lat_lang
    let pass_data = {
      get: {
        slug: id_value.section_slug,
        category_slug: encodeURI(JSON.stringify(cat_value)),
        lat: lat_lang.lat,
        lng: lat_lang.lang,
      },
    };
    console.log("get_vendors pass data ");
    let response = await api("/section_vendors", pass_data);
    console.log("section_vendors response :", response);
    if (response.status_code === 200) {
      if (response.response.hasOwnProperty("vendors")) {
        response.response.vendors;
        SetVendorsList(response.response.vendors);
      } else {
        SetVendorsList([]);
      }
    }
    SetShowLoader(false);
  }

  async function get_categories() {
    SetShowCategoryLoader(true)
    let pass_data = {
      get: {
        section_slug: id_value.section_slug,
        // category_slug: id_value.category_slug,
        category_slug: url_params.get("n"),
        parents: 1,
      },
    };
    console.log("get_categories pass data ");
    let response = await api("/categories", pass_data);
    console.log("categories response :", response);
    if (response.status_code === 200) {
      if (response.response.hasOwnProperty("product_categories_current")) {
        SetCategoryData(response.response.product_categories_current[0]);
        if (
          response.response.product_categories_current[0].hasOwnProperty(
            "children"
          )
        ) {
          SetCategoryList(
            response.response.product_categories_current[0].children
          );
        } else {
          SetCategoryList([]);
          SetShowCategoryLoader(false);
        }
      }else{
        SetShowCategoryLoader(false);
      }
      SetShowCategoryLoader(false);
    }
  }

  function get_outlet_slug(outlets: any) {
    let return_value = outlets.find((ele: any) => ele.checked == true);
    if (return_value != undefined) {
      return return_value.slug;
    } else {
      return "";
    }
  }

  function get_vendor_details(get_data: any) {
    let return_value;
    let index_value = get_data.outlets.findIndex(
      (ele: any) => ele.checked == true
    );
    if (index_value != -1) {
      let est_time = Math.floor(get_data.outlets[index_value].etd_seconds / 60);
      return_value =
        get_data.outlets[index_value].distance_kms +
        " Km - " +
        est_time +
        " Mins";
    }
    return return_value;
  }

  function SaveContext(data: any) {
    console.log("SaveContext:", data);
    context.SetAppData((prevValue: any) => {
      prevValue.restaurant.brand_id = data.brand_id;
      prevValue.restaurant.brand_name = data.brand_name;
      prevValue.restaurant.favourite = data.favourite;
      prevValue.restaurant.outlets = data.outlets;
      data?.outlets?.map((ele: any) => {
        ele.is_loading = false;
      });
      prevValue.selected_vendor = data;
      let selected_outlet_value;
      let outlet_value = data.outlets;
      // prevValue.selected_vendor_outlet = outlet_value[0];

      let checked_outlet = data.outlets.find((ele: any) => ele.checked == true);
      prevValue.selected_vendor_outlet = checked_outlet;

      console.log("prevalue on click vendor :", prevValue);
      return { ...prevValue };
    });
  }

  const handleDragStart = (e: any) => e.preventDefault();

  return (
    <div className="page-main margin-footer  pb-5 mb-5 px-sm-0 ">
      {/* category */}
      <div className="mb-3">
        {show_category_loader ? (
          <PopularFoodVarieties />
        ) : Object.keys(category_data).length > 0 ? (
          <div className="py-3 card shadow-bottom food-page">
            <div className=" pt-2">
              <div className="d-flex ps-2">
                <h5 className="mb-1 pb-0 ps-2">{category_data.name}</h5>
              </div>
              <div className="row">
                {category_list?.map((c_ele: any, c_index: any) => (
                  <div
                    className="col-lg-2 col-md-3 col-3 p-2 cursor"
                    key={c_index}
                    onDragStart={handleDragStart}
                    onClick={() => {
                      // SaveContext(c_ele);
                      context.SetAppData((prevValue: any) => {
                        prevValue.selected_category = c_ele;
                        return { ...prevValue };
                      });
                      console.log("on click set parms");
                      // url_params.set("n", c_ele.slug);
                      SetUrlParams({ n: c_ele.slug });
                    }}
                  >
                    {/* <Link
                      to={
                        "/" +
                        id_value.section_slug +
                        "/category/" +
                        c_ele.slug +
                        "/"
                      }
                    > */}
                    <div
                      className="text-center"
                      onClick={() => {
                        // console.log("on click set parms")
                        // url_params.set("n", c_ele.slug);
                      }}
                    >
                      {/* <img src={c_ele.image} className="food-img" /> */}
                      {c_ele.image == "" || c_ele.image == null ? (
                        <img
                          src={require("../../../assets/img/no-img.jpg")}
                          className="food-img"
                        />
                      ) : (
                        <ImageContainer
                          src={AppHelper.imageSrc("", c_ele.image, {
                            format: "webp",
                            width: 320,
                          })}
                          thumb={AppHelper.imageSrc("", c_ele.image, {
                            format: "webp",
                            width: 320,
                            quality: 10,
                          })}
                          class_name="food-img"
                          onIsVisible={() => {
                            console.log("ImageContainer On Is visible");
                          }}
                        />
                      )}
                      <div className="text-center py-2">
                        <p className="food-name oneline mb-0">{c_ele.name}</p>
                      </div>
                    </div>
                    {/* </Link> */}
                  </div>
                ))}
              </div>
            </div>
          </div>
        ) : null}
      </div>
      {/* resturants */}
      {true ? (<></>) : !show_loader ? (
        <div className="mt-2 py-3 card shadow-bottom">
          <div>
            <div className="d-flex ps-2 align-items-center">
              <img
                src={require("../../../assets/icon/restaurant2.png")}
                className="head-img"
              />
              <h5 className="mb-0 pb-0 ps-1">Restaurants</h5>
            </div>
            {/* <p className="ps-2">Find tastie centers near you</p> */}
          </div>
          <div className="mt-3">
            {vendors_list.length > 0 ? (
              <div className="row">
                {vendors_list?.map((v_ele: any, v_index: any) => (
                  <div
                    className="col-12 col-md-6 col-lg-4 cursor restaurant-list__item"
                    key={v_index}
                  >
                    <Link
                      to={
                        "/" +
                        id_value.section_slug +
                        "/" +
                        get_outlet_slug(v_ele.outlets) +
                        "?category=" +
                        url_params.get("n")
                      }
                    >
                      <div
                        className="row pt-3 restaurant-list__resta_card"
                        onClick={() => {
                          SaveContext(v_ele);
                        }}
                      >
                        <div className="col-5 p-0">
                          <div className="container p-0">
                            {v_ele.hasOwnProperty("image") ? (
                              // <img
                              //   src={AppHelper.imageSrc(
                              //     context.app_data.image_base_url,
                              //     v_ele.image,
                              //     { format: "webp", width: 320 }
                              //   )}
                              //   className="near-img"
                              // />
                              <ImageContainer
                                src={AppHelper.imageSrc(
                                  context.app_data.image_base_url,
                                  v_ele.image,
                                  { format: "webp", width: 320 }
                                )}
                                thumb={AppHelper.imageSrc(
                                  context.app_data.image_base_url,
                                  v_ele.image,
                                  {
                                    format: "webp",
                                    width: 320,
                                    quality: 10,
                                  }
                                )}
                                class_name="near-img"
                                onIsVisible={() => {
                                  console.log("ImageContainer On Is visible");
                                }}
                              />
                            ) : (
                              <img
                                src={require("../../../assets/img/dhosa.jpg")}
                                className="near-img"
                              />
                            )}
                          </div>
                        </div>
                        <div className="col-7 pr-0">
                          <h6 className="marg mb-1">{v_ele.brand_name}</h6>
                          <div className="d-flex align-items-center">
                            {/* North Indian, South Indian cousine detail views{" "} */}
                            <img
                              src={require("../../../assets/icon/scooter.png")}
                              className="head-img pe-2"
                            />
                            <p className="v-small">
                              {get_vendor_details(v_ele)}
                            </p>
                          </div>{" "}
                        </div>
                      </div>
                    </Link>
                  </div>
                ))}
              </div>
            ) : (
              <div className="text-center p-3">
                <img
                  src={require("../../../assets/icon/no-restaurant.png")}
                  className="empty-img"
                />
                <p className="pt-2">No near by resturant</p>
              </div>
            )}
          </div>
        </div>
      ) : (
        <AllRestaurants />
      )}
  
      <Search from_category={true} category={url_params.get("n")} />
    </div>
  );
}
