import React, { useState, useEffect, useContext } from "react";
import { Link, useOutletContext, useSearchParams } from "react-router-dom";
import DataContext from "../../../utils/Context/DataContext";
import { AppConfig } from "../../../config";
import { Dialog, makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    [theme.breakpoints.down("xs")]: {
      alignItems: "flex-end", // push the dialog to bottom
    },
  },
  paper: {
    // make the content full width
    [theme.breakpoints.down("xs")]: {
      margin: 0,
      maxWidth: "100%",
      width: "100%",
      overflow: "scroll",
      paddingBottom: "45px",
      maxHeight: "100% !important",
    },
    [theme.breakpoints.up("md")]: {
      overflow: "scroll",
      maxWidth: "50%",
      width: "100%",
      height: "",
    },
  },
}));

export default function OrderPageNew(props: any) {
  const classes = useStyles();
  const [add_delivery_open, SetAddDeliveryOpen] = useState(false);
  const [add_vendor_open, SetAddvendorOpen] = useState(false);

  return (
    <div className="mb-5 p-2 custom-container bg-body-color">
      <div className="card radius-10 shadow mb-2">
        <div className="row  p-2">
          <div className="col-6 d-flex align-items-center">
            <div className="del-type ">Hyperlocal</div>
            <div className="ms-2 pay_type">COD</div>
          </div>
          <div className="col-6 text-end">
            <div className="o-date">17-08-24 03:27pm</div>
          </div>
        </div>
        <div className="shop-div row align-items-center">
          <div className="col-2 p-0">
            <img
              src={
                AppConfig.CDN_Image_url + "aa5ea240d88e138ba9158c523842b4fd.jpg"
              }
              className="os-img"
            />
          </div>
          <div className="col-7 d-flex p-0">
            <div className="line-h-17px">
              <span className="os-name">New Gowri Sankar Hotel</span>
              <span className="os-sta">
                your order delivery to delivery partner
              </span>
            </div>
          </div>
          <div className="col-3 p-0 end">
            <div className="os-icon shadow center">
              <img
                src={
                  AppConfig.CDN_Media_url +
                  "a78b858bd49bf56c9fa495b641269677.svg"
                }
                className="w-20px"
              />
            </div>
            <div className="ms-3 os-icon shadow center">
              <img
                src={
                  AppConfig.CDN_Media_url +
                  "ce8b7d884ffdb51228e7577cc36f38c0.svg"
                }
                className="w-20px"
              />
            </div>
          </div>
        </div>
        <div className="px-2 pt-3">
          <div className="d-flex mb-2">
            <img
              src={
                AppConfig.CDN_Image_url + "e301ce3af353aab421a2f76c898a439d.png"
              }
              className="w-12px mt-1"
            />
            <div className="os-qty">1 ×</div>
            <div className="os-pname">
              Fiery Sausage & Paprika Medium New Hand Tossed
            </div>
          </div>
          <div className="d-flex mb-2">
            <img
              src={
                AppConfig.CDN_Image_url + "e301ce3af353aab421a2f76c898a439d.png"
              }
              className="w-12px mt-1"
            />
            <div className="os-qty">1 ×</div>
            <div className="os-pname">
              Fiery Sausage & Paprika Medium New Hand Tossed
            </div>
          </div>
          <hr className="os-hr m-0" />
          <div className="row py-2">
            <div className="col-6 os-tot">Total Bill</div>
            <div className="col-6 end os-tot">₹ 576.00</div>
          </div>
        </div>
        <div className="shop-div row">
          <div className="col-2 p-0">
            <img
              src={
                AppConfig.CDN_Image_url + "d112a6cd7325a2ce2b456d5f22baea4a.jpg"
              }
              className="os-del-img"
            />
          </div>
          <div className="col-7 d-flex p-0">
            <div className="line-h-17px">
              <span className="os-name">Delivery Person</span>
              <span className="os-sta">is going to pickup your orders</span>
            </div>
          </div>
          <div className="col-3 p-0 end">
            <div className="os-icon shadow center">
              <img
                src={
                  AppConfig.CDN_Media_url +
                  "a78b858bd49bf56c9fa495b641269677.svg"
                }
                className="w-20px"
              />
            </div>
            <div className="ms-3 os-icon shadow center">
              <img
                src={
                  AppConfig.CDN_Media_url +
                  "ce8b7d884ffdb51228e7577cc36f38c0.svg"
                }
                className="w-20px"
              />
            </div>
          </div>
        </div>
        <div
          className="row align-items-center p-3 border-top"
          onClick={() => {
            SetAddvendorOpen(true);
          }}
        >
          <div className="col-11 ps-0 os-instra">Add vendor instructions</div>
          <div className="col-1 p-0 end">
            <img
              src={
                AppConfig.CDN_Media_url + "77ff56db2e971c20be91c491e5b50729.svg"
              }
              className="w-20px"
            />
          </div>
        </div>
        <div className="row align-items-center p-3 border-top">
          <div className="col-11 ps-0 os-instra">Add Delivery instructions</div>
          <div className="col-1 p-0 end">
            <img
              src={
                AppConfig.CDN_Media_url + "77ff56db2e971c20be91c491e5b50729.svg"
              }
              className="w-20px"
            />
          </div>
        </div>
      </div>

      <div className="card radius-10 shadow mb-2">
        <div className="row  p-2">
          <div className="col-6 d-flex align-items-center">
            <div className="del-type ">Hyperlocal</div>
            <div className="ms-2 pay_type">COD</div>
          </div>
          <div className="col-6 text-end">
            <div className="o-date">17-08-24 03:27pm</div>
          </div>
        </div>
        <div className="shop-div row align-items-center">
          <div className="col-2 p-0">
            <img
              src={
                AppConfig.CDN_Image_url + "aa5ea240d88e138ba9158c523842b4fd.jpg"
              }
              className="os-img"
            />
          </div>
          <div className="col-7 d-flex p-0">
            <div className="line-h-17px">
              <span className="os-name">New Gowri Sankar Hotel</span>
              {/* <span className="os-sta">
                your order delivery to delivery partner
              </span> */}
            </div>
          </div>
          <div className="col-3 p-0 end">
            <div className="os-status pe-1">Delivered</div>
            <img
              src={
                AppConfig.CDN_Media_url + "58b18713d1577491802b06ac3c3bfd41.svg"
              }
              className="w-16px"
            />
          </div>
        </div>
        <div className="px-2 pt-3">
          <div className="d-flex mb-2">
            <img
              src={
                AppConfig.CDN_Image_url + "e301ce3af353aab421a2f76c898a439d.png"
              }
              className="w-12px mt-1"
            />
            <div className="os-qty">1 ×</div>
            <div className="os-pname">
              Fiery Sausage & Paprika Medium New Hand Tossed
            </div>
          </div>
          <div className="d-flex mb-2">
            <img
              src={
                AppConfig.CDN_Image_url + "e301ce3af353aab421a2f76c898a439d.png"
              }
              className="w-12px mt-1"
            />
            <div className="os-qty">1 ×</div>
            <div className="os-pname">
              Fiery Sausage & Paprika Medium New Hand Tossed
            </div>
          </div>
          <hr className="os-hr m-0" />
          <div className="row py-2">
            <div className="col-6 os-tot">Total Bill</div>
            <div className="col-6 end os-tot">₹ 576.00</div>
          </div>
        </div>
        <div className="p-2">
          <div className="os-rr-div row">
            <div className="col-6 os-rr-border center">
              <img
                src={
                  AppConfig.CDN_Media_url +
                  "10e42eefca20e05c90acd690188fc6be.svg"
                }
                className="w-14px"
              />
              <div className="os-rate ps-1">Rate Order</div>
            </div>
            <div className="col-6 center">
              <img
                src={
                  AppConfig.CDN_Media_url +
                  "624d15ae439e017f3938d44e903a2823.svg"
                }
                className="w-16px"
              />
              <div className="os-rate ps-1">Reorder</div>
            </div>
          </div>
        </div>
      </div>

      <div className="card radius-10 shadow mb-2">
        <div className="row  p-2">
          <div className="col-6 d-flex align-items-center">
            <div className="del-type ">Hyperlocal</div>
            <div className="ms-2 pay_type">COD</div>
          </div>
          <div className="col-6 text-end">
            <div className="o-date">17-08-24 03:27pm</div>
          </div>
        </div>
        <div className="shop-div row align-items-center">
          <div className="col-2 p-0">
            <img
              src={
                AppConfig.CDN_Image_url + "aa5ea240d88e138ba9158c523842b4fd.jpg"
              }
              className="os-img"
            />
          </div>
          <div className="col-7 d-flex p-0">
            <div className="line-h-17px">
              <span className="os-name">New Gowri Sankar Hotel</span>
              {/* <span className="os-sta">
                your order delivery to delivery partner
              </span> */}
            </div>
          </div>
          <div className="col-3 p-0 end">
            <div className="os-status pe-1">Delivered</div>
            <img
              src={
                AppConfig.CDN_Media_url + "58b18713d1577491802b06ac3c3bfd41.svg"
              }
              className="w-16px"
            />
          </div>
        </div>
        <div className="px-2 pt-3">
          <div className="d-flex mb-2">
            <img
              src={
                AppConfig.CDN_Image_url + "e301ce3af353aab421a2f76c898a439d.png"
              }
              className="w-12px mt-1"
            />
            <div className="os-qty">1 ×</div>
            <div className="os-pname">
              Fiery Sausage & Paprika Medium New Hand Tossed
            </div>
          </div>
          <div className="d-flex mb-2">
            <img
              src={
                AppConfig.CDN_Image_url + "e301ce3af353aab421a2f76c898a439d.png"
              }
              className="w-12px mt-1"
            />
            <div className="os-qty">1 ×</div>
            <div className="os-pname">
              Fiery Sausage & Paprika Medium New Hand Tossed
            </div>
          </div>
          <hr className="os-hr m-0" />
          <div className="row py-2">
            <div className="col-6 os-tot">Total Bill</div>
            <div className="col-6 end os-tot">₹ 576.00</div>
          </div>
        </div>

        <div className="p-2">
          <div className="col-6 os-rr-border center">
            <img
              src={
                AppConfig.CDN_Media_url + "10e42eefca20e05c90acd690188fc6be.svg"
              }
              className="w-14px"
            />
            <div className="os-rate ps-1">Rate Order</div>
          </div>
          <div className="col-6 center">
            <img
              src={
                AppConfig.CDN_Media_url + "624d15ae439e017f3938d44e903a2823.svg"
              }
              className="w-16px"
            />
            <div className="os-rate ps-1">Reorder</div>
          </div>

          <div className="row py-2">
            <div className="col-6 border-right text-center">
              <div className="os-rate-head pb-2">your Food Rating</div>
              <div className="center ">
                <div className="os-rate-sub pe-1">4</div>
                <img
                  src={
                    AppConfig.CDN_Media_url +
                    "f9a5abcd0b1ce37ed14d55bc77bd20a7.svg"
                  }
                  className="w-10px me-2  mb-1"
                />
                <div className="os-rr-border-left os-rate-sub ps-2">
                  Like it
                </div>
              </div>
            </div>
            <div className="col-6  text-center">
              <div className="os-rate-head pb-2">your Delivery Rating</div>
              <div className="center">
                <div className="os-rate-sub pe-1">4</div>
                <img
                  src={
                    AppConfig.CDN_Media_url +
                    "f9a5abcd0b1ce37ed14d55bc77bd20a7.svg"
                  }
                  className="w-10px me-2  mb-1"
                />
                <div className="os-rr-border-left os-rate-sub ps-2">
                  Fast Delivery
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Rate for delivery */}
      <div className="card radius-10 shadow mb-2">
        <div className="row  p-2">
          <div className="col-6 d-flex align-items-center">
            <div className="del-type ">Hyperlocal</div>
            <div className="ms-2 pay_type">COD</div>
          </div>
          <div className="col-6 text-end">
            <div className="o-date">17-08-24 03:27pm</div>
          </div>
        </div>
        <div className="shop-div row align-items-center">
          <div className="col-2 p-0">
            <img
              src={
                AppConfig.CDN_Image_url + "aa5ea240d88e138ba9158c523842b4fd.jpg"
              }
              className="os-img"
            />
          </div>
          <div className="col-7 d-flex p-0">
            <div className="line-h-17px">
              <span className="os-name">New Gowri Sankar Hotel</span>
              {/* <span className="os-sta">
                your order delivery to delivery partner
              </span> */}
            </div>
          </div>
          <div className="col-3 p-0 end">
            <div className="os-status pe-1">Delivered</div>
            <img
              src={
                AppConfig.CDN_Media_url + "58b18713d1577491802b06ac3c3bfd41.svg"
              }
              className="w-16px"
            />
          </div>
        </div>
        <div className="px-2 pt-3">
          <div className="d-flex mb-2">
            <img
              src={
                AppConfig.CDN_Image_url + "e301ce3af353aab421a2f76c898a439d.png"
              }
              className="w-12px mt-1"
            />
            <div className="os-qty">1 ×</div>
            <div className="os-pname">
              Fiery Sausage & Paprika Medium New Hand Tossed
            </div>
          </div>
          <div className="d-flex mb-2">
            <img
              src={
                AppConfig.CDN_Image_url + "e301ce3af353aab421a2f76c898a439d.png"
              }
              className="w-12px mt-1"
            />
            <div className="os-qty">1 ×</div>
            <div className="os-pname">
              Fiery Sausage & Paprika Medium New Hand Tossed
            </div>
          </div>
          <hr className="os-hr m-0" />
          <div className="row py-2">
            <div className="col-6 os-tot">Total Bill</div>
            <div className="col-6 end os-tot">₹ 576.00</div>
          </div>
        </div>
        <div className="row align-items-center p-3 border-top">
          <div className="col-11 ps-0 os-instra">Add vendor instructions</div>
          <div className="col-1 p-0 end">
            <img
              src={
                AppConfig.CDN_Media_url + "77ff56db2e971c20be91c491e5b50729.svg"
              }
              className="w-20px"
            />
          </div>
        </div>
        <div className="row align-items-center p-3 border-top">
          <div className="col-11 ps-0 os-instra">Add Delivery instructions</div>
          <div className="col-1 p-0 end">
            <img
              src={
                AppConfig.CDN_Media_url + "77ff56db2e971c20be91c491e5b50729.svg"
              }
              className="w-20px"
            />
          </div>
        </div>
        <div className="p-2">
          <div className="os-rr-div row">
            <div className="col-6 os-rr-border center">
              <img
                src={
                  AppConfig.CDN_Media_url +
                  "10e42eefca20e05c90acd690188fc6be.svg"
                }
                className="w-14px"
              />
              <div className="os-rate ps-1">Rate Order</div>
            </div>
            <div className="col-6 center">
              <img
                src={
                  AppConfig.CDN_Media_url +
                  "624d15ae439e017f3938d44e903a2823.svg"
                }
                className="w-16px"
              />
              <div className="os-rate ps-1">Reorder</div>
            </div>
          </div>

          <div className="row py-2">
            <div className="col-6 border-right text-center">
              <div className="os-rate-head pb-2">your Food Rating</div>
              <div className="center ">
                <div className="os-rate-sub pe-1">4</div>
                <img
                  src={
                    AppConfig.CDN_Media_url +
                    "f9a5abcd0b1ce37ed14d55bc77bd20a7.svg"
                  }
                  className="w-10px me-2  mb-1"
                />
                <div className="os-rr-border-left os-rate-sub ps-2">
                  Like it
                </div>
              </div>
            </div>
            <div className="col-6  text-center">
              <div className="os-rate-head pb-2">
                you Haven't Rated This Delivery Yet
              </div>
              <div className="center ">
                <img
                  src={
                    AppConfig.CDN_Media_url +
                    "10e42eefca20e05c90acd690188fc6be.svg"
                  }
                  className="w-10px"
                />
                <div className="os-rate-sub ps-1">Rate For Delivery</div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Rate for delivery */}
      <div className="card radius-10 shadow mb-2">
        <div className="row  p-2">
          <div className="col-6 d-flex align-items-center">
            <div className="del-type ">Hyperlocal</div>
            <div className="ms-2 pay_type">COD</div>
          </div>
          <div className="col-6 text-end">
            <div className="o-date">17-08-24 03:27pm</div>
          </div>
        </div>
        <div className="shop-div row align-items-center">
          <div className="col-2 p-0">
            <img
              src={
                AppConfig.CDN_Image_url + "aa5ea240d88e138ba9158c523842b4fd.jpg"
              }
              className="os-img"
            />
          </div>
          <div className="col-7 d-flex p-0">
            <div className="line-h-17px">
              <span className="os-name">New Gowri Sankar Hotel</span>
              {/* <span className="os-sta">
                your order delivery to delivery partner
              </span> */}
            </div>
          </div>
          <div className="col-3 p-0 end">
            <div className="os-status pe-1">Delivered</div>
            <img
              src={
                AppConfig.CDN_Media_url + "58b18713d1577491802b06ac3c3bfd41.svg"
              }
              className="w-16px"
            />
          </div>
        </div>
        <div className="px-2 pt-3">
          <div className="d-flex mb-2">
            <img
              src={
                AppConfig.CDN_Image_url + "e301ce3af353aab421a2f76c898a439d.png"
              }
              className="w-12px mt-1"
            />
            <div className="os-qty">1 ×</div>
            <div className="os-pname">
              Fiery Sausage & Paprika Medium New Hand Tossed
            </div>
          </div>
          <div className="d-flex mb-2">
            <img
              src={
                AppConfig.CDN_Image_url + "e301ce3af353aab421a2f76c898a439d.png"
              }
              className="w-12px mt-1"
            />
            <div className="os-qty">1 ×</div>
            <div className="os-pname">
              Fiery Sausage & Paprika Medium New Hand Tossed
            </div>
          </div>
          <hr className="os-hr m-0" />
          <div className="row py-2">
            <div className="col-6 os-tot">Total Bill</div>
            <div className="col-6 end os-tot">₹ 576.00</div>
          </div>
        </div>
        <div className="p-2">
          <div className="os-rr-div row">
            <div className="col-6 os-rr-border center">
              <img
                src={
                  AppConfig.CDN_Media_url +
                  "10e42eefca20e05c90acd690188fc6be.svg"
                }
                className="w-14px"
              />
              <div className="os-rate ps-1">Rate Order</div>
            </div>
            <div className="col-6 center">
              <img
                src={
                  AppConfig.CDN_Media_url +
                  "624d15ae439e017f3938d44e903a2823.svg"
                }
                className="w-16px"
              />
              <div className="os-rate ps-1">Reorder</div>
            </div>
          </div>

          <div className="row py-2">
            <div className="col-6 border-right text-center">
              <div className="os-rate-head pb-2">
                you Haven't Rated This Food Yet
              </div>
              <div className="center ">
                <img
                  src={
                    AppConfig.CDN_Media_url +
                    "10e42eefca20e05c90acd690188fc6be.svg"
                  }
                  className="w-10px"
                />
                <div className="os-rate-sub ps-1">Rate For Food</div>
              </div>
            </div>
            <div className="col-6  text-center">
              <div className="os-rate-head pb-2">your Delivery Rating</div>
              <div className="center ">
                <div className="os-rate-sub pe-1">4</div>
                <img
                  src={
                    AppConfig.CDN_Media_url +
                    "f9a5abcd0b1ce37ed14d55bc77bd20a7.svg"
                  }
                  className="w-10px me-2  mb-1"
                />
                <div className="os-rr-border-left os-rate-sub ps-2">
                  Fast Delivery
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* cancelled order */}
      <div className="card radius-10 shadow mb-2">
        <div className="row  p-2">
          <div className="col-6 d-flex align-items-center">
            <div className="del-type ">Hyperlocal</div>
            <div className="ms-2 pay_type">Online</div>
          </div>
          <div className="col-6 text-end">
            <div className="o-date">17-08-24 03:27pm</div>
          </div>
        </div>
        <div className="shop-div row align-items-center">
          <div className="col-2 p-0">
            <img
              src={
                AppConfig.CDN_Image_url + "aa5ea240d88e138ba9158c523842b4fd.jpg"
              }
              className="os-img"
            />
          </div>
          <div className="col-7 d-flex p-0">
            <div className="line-h-17px">
              <span className="os-name">New Gowri Sankar Hotel</span>
              {/* <span className="os-sta">
                your order delivery to delivery partner
              </span> */}
            </div>
          </div>
          <div className="col-3 p-0 end">
            <div className="os-status-red pe-1">Cancelled</div>
            <img
              src={
                AppConfig.CDN_Media_url + "d33ce1f2523b54f7672708ecfe300d76.svg"
              }
              className="w-16px"
            />
          </div>
        </div>
        <div className="px-2 pt-3">
          <div className="d-flex mb-2">
            <img
              src={
                AppConfig.CDN_Image_url + "e301ce3af353aab421a2f76c898a439d.png"
              }
              className="w-12px mt-1"
            />
            <div className="os-qty">1 ×</div>
            <div className="os-pname">
              Fiery Sausage & Paprika Medium New Hand Tossed
            </div>
          </div>
          <div className="d-flex mb-2">
            <img
              src={
                AppConfig.CDN_Image_url + "e301ce3af353aab421a2f76c898a439d.png"
              }
              className="w-12px mt-1"
            />
            <div className="os-qty">1 ×</div>
            <div className="os-pname">
              Fiery Sausage & Paprika Medium New Hand Tossed
            </div>
          </div>
          <hr className="os-hr m-0" />
          <div className="row py-2">
            <div className="col-6 os-tot">Total Bill</div>
            <div className="col-6 end os-tot">₹ 576.00</div>
          </div>
        </div>
        <div className="p-2">
          <div className="os-rr-div row">
            <div className="col-6 os-rr-border center">
              <img
                src={
                  AppConfig.CDN_Media_url +
                  "15f094a572b2c2f3ce21110e79a56282.svg"
                }
                className="w-14px"
              />
              <div className="os-rate ps-1">Refund details</div>
            </div>
            <div className="col-6 center">
              <img
                src={
                  AppConfig.CDN_Media_url +
                  "624d15ae439e017f3938d44e903a2823.svg"
                }
                className="w-16px"
              />
              <div className="os-rate ps-1">Reorder</div>
            </div>
          </div>
        </div>
      </div>

      {/* Pickup & Drop */}
      <div className="card radius-10 shadow mb-2">
        <div className="row  p-2">
          <div className="col-6 d-flex align-items-center">
            <div className="del-type ">Hyperlocal</div>
            <div className="ms-2 pay_type">COD</div>
          </div>
          <div className="col-6 text-end">
            <div className="o-date">17-08-24 03:27pm</div>
          </div>
        </div>
        <div className="shop-div row align-items-center">
          <div className="col-2 p-0">
            <img
              src={
                AppConfig.CDN_Image_url + "aa5ea240d88e138ba9158c523842b4fd.jpg"
              }
              className="os-img"
            />
          </div>
          <div className="col-10 d-flex p-0">
            <div className="line-h-17px">
              <div className="os-name">SuperApp Delivery</div>
              <div className="os-sta ps-0">Pickup & Drop</div>
            </div>
          </div>
        </div>
        <div className="px-2 pt-3">
          <div className="row pb-2">
            <div className="col-1 p-0">
              <img
                src={
                  AppConfig.CDN_Image_url +
                  "69e22d8519c6739a9feceef2d109c256.png"
                }
                className="w-20px"
              />
            </div>
            <div className="col-8 p-0">
              <div className="d-flex align-items-center pb-1">
                <div className="os-dark pe-1">Pickup</div>
                <div className="os-light pe-1">from</div>
                <div className="os-dark uppercase">Home</div>
              </div>
              <div className="os-rate">Person 1</div>
              <div className="os-pname ps-0">
                Chennai - Theni Highway, Endapuli KamatchiPuram, Theni, Tamil
                Nadu, India, 625604
              </div>
            </div>
            <div className="col-3 p-0 end">
              <div className="os-icon shadow center">
                <img
                  src={
                    AppConfig.CDN_Media_url +
                    "a78b858bd49bf56c9fa495b641269677.svg"
                  }
                  className="w-20px"
                />
              </div>
              <div className="ms-3 os-icon shadow center">
                <img
                  src={
                    AppConfig.CDN_Media_url +
                    "ce8b7d884ffdb51228e7577cc36f38c0.svg"
                  }
                  className="w-20px"
                />
              </div>
            </div>
          </div>
          <div className="row pb-2">
            <div className="col-1 p-0">
              <img
                src={
                  AppConfig.CDN_Image_url +
                  "2f0620ddc47a95162db3b88a35358317.png"
                }
                className="w-20px"
              />
            </div>
            <div className="col-8 p-0">
              <div className="d-flex align-items-center pb-1">
                <div className="os-dark pe-1">Delivery</div>
                <div className="os-light pe-1">to</div>
                <div className="os-dark uppercase">Office</div>
              </div>
              <div className="os-rate">Person 1</div>
              <div className="os-pname ps-0">
                Mary Matha College Road, Theni, Tamil Nadu, India, 625604
              </div>
            </div>
            <div className="col-3 p-0 end">
              <div className="os-icon shadow center">
                <img
                  src={
                    AppConfig.CDN_Media_url +
                    "a78b858bd49bf56c9fa495b641269677.svg"
                  }
                  className="w-20px"
                />
              </div>
              <div className="ms-3 os-icon shadow center">
                <img
                  src={
                    AppConfig.CDN_Media_url +
                    "ce8b7d884ffdb51228e7577cc36f38c0.svg"
                  }
                  className="w-20px"
                />
              </div>
            </div>
          </div>
          <hr className="os-hr m-0" />
          <div className="row py-2">
            <div className="col-6 os-tot">Total Bill</div>
            <div className="col-6 end os-tot">₹ 576.00</div>
          </div>
        </div>
        <div className="shop-div row">
          <div className="col-2 p-0">
            <img
              src={
                AppConfig.CDN_Image_url + "d112a6cd7325a2ce2b456d5f22baea4a.jpg"
              }
              className="os-del-img"
            />
          </div>
          <div className="col-7 d-flex p-0">
            <div className="line-h-17px">
              <span className="os-name">Delivery Person</span>
              <span className="os-sta">is on the way to pickup</span>
            </div>
          </div>
          <div className="col-3 p-0 end">
            <div className="os-icon shadow center">
              <img
                src={
                  AppConfig.CDN_Media_url +
                  "a78b858bd49bf56c9fa495b641269677.svg"
                }
                className="w-20px"
              />
            </div>
            <div className="ms-3 os-icon shadow center">
              <img
                src={
                  AppConfig.CDN_Media_url +
                  "ce8b7d884ffdb51228e7577cc36f38c0.svg"
                }
                className="w-20px"
              />
            </div>
          </div>
        </div>
        <div className="row align-items-center p-3 border-top">
          <div className="col-11 ps-0 os-instra">Add vendor instructions</div>
          <div className="col-1 p-0 end">
            <img
              src={
                AppConfig.CDN_Media_url + "77ff56db2e971c20be91c491e5b50729.svg"
              }
              className="w-20px"
            />
          </div>
        </div>
        <div className="row align-items-center p-3 border-top">
          <div className="col-11 ps-0 os-instra">Add Delivery instructions</div>
          <div className="col-1 p-0 end">
            <img
              src={
                AppConfig.CDN_Media_url + "77ff56db2e971c20be91c491e5b50729.svg"
              }
              className="w-20px"
            />
          </div>
        </div>
      </div>

      {/* dialog */}

      <Dialog
        classes={{ container: classes.root, paper: classes.paper }}
        className="center"
        open={add_delivery_open}
        onClose={() => {
          SetAddDeliveryOpen(false);
        }}
      >
        <div className="row align-items-center py-3 px-2">
          <div className="col-10">
            <div className="os-dialog-head">Delivery Instructions</div>
          </div>
          <div
            className="col-2 end"
            onClick={() => {
              SetAddDeliveryOpen(false);
            }}
          >
            <img
              src={
                AppConfig.CDN_Media_url + "f0e8463e68a70f6e4a7ae696c626f8aa.svg"
              }
              className="w-25px"
            />
          </div>
        </div>
        <div className="text-center p-3">
          <textarea
            className="input-textarea"
            placeholder="eg: Just knock the door"
          ></textarea>
          <div className="mt-3 btn os-save-btn">Save Instruction</div>
        </div>
      </Dialog>

      <Dialog
        classes={{ container: classes.root, paper: classes.paper }}
        className="center"
        open={add_vendor_open}
        onClose={() => {
          SetAddvendorOpen(false);
        }}
      >
        <div className="row align-items-center py-3 px-2">
          <div className="col-10">
            <div className="os-dialog-head">Vendor Instructions</div>
          </div>
          <div
            className="col-2 end"
            onClick={() => {
              SetAddvendorOpen(false);
            }}
          >
            <img
              src={
                AppConfig.CDN_Media_url + "f0e8463e68a70f6e4a7ae696c626f8aa.svg"
              }
              className="w-25px"
            />
          </div>
        </div>
        <div className="text-center p-3">
          <textarea
            className="input-textarea"
            placeholder="eg: Make a spicy food"
          ></textarea>
          <div className="mt-3 btn os-save-btn">Save Instruction</div>
        </div>
      </Dialog>
    </div>
  );
}
